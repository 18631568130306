import BaseModel from "./BaseModel"
import Link from "./Link"
import Account from "./Account";

export default class Accounts extends BaseModel {
  current: Account[] = []
  links: Link[] = []

  setCurrent(a?:[]) {
    return a ? a.map(aa => new Account().init(aa)) : []
  }

  setLinks(a?:[]) {
    return a ? a.map(aa => new Link().init(aa)) : []
  }
}
