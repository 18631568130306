import { observer } from "mobx-react"
import React from "react"
import LazyStorePanel from "./LazyStorePanel"
import EncounterTagsStore from "../stores/EncounterTagsStore";
import { computed, observable } from "mobx";
import _ from "lodash";
import { Tag } from "../models/Tag";
import { Chip, Tab, Tabs } from "@material-ui/core";
import ReactSelectMaterialUi from 'react-select-material-ui'
import ApiClientFactory from '../api/ApiClientFactory'

type Props = {
  encounterTagsStore: EncounterTagsStore
}

type GroupedTags = {
  category: string
  tags: Tag[]
}

@observer
export default class EncounterTagsComponent extends React.Component<Props> {
  @observable _selectedCategory?: string

  @computed get selectedCategory () {
    let cat = this._selectedCategory

    if (cat && !_.find(this.groupedTags, gt => gt.category === cat)) {
      cat = undefined
    }

    return cat
      || (
        this.groupedTags.length
          ? this.groupedTags[0].category
          : undefined
      )
  }

  @computed get groupedTags (): GroupedTags[] {
    const groupedTags: GroupedTags[] = []

    const data = this.props.encounterTagsStore.data

    if (!data) {
      return []
    }

    data.current
      .filter(t => data.selected.indexOf(t.id) === -1)
      .forEach(t => {
        let group = _.find(groupedTags, g => g.category === t.category)

        if (!group) {
          group = {
            category: t.category,
            tags: []
          }

          groupedTags.push(group)
        }

        group.tags.push(t)
      })

    return groupedTags
  }

  @computed get selectedTags (): Tag[] {
    const data = this.props.encounterTagsStore.data
    if (!data) {
      return []
    }

    return data.current.filter(t => data.selected.indexOf(t.id) > -1)
  }

  @computed get availableTags (): Tag[] {
    const data = this.props.encounterTagsStore.data
    if (!data) {
      return []
    }

    return _.sortBy(data.current.filter(t => data.selected.indexOf(t.id) === -1), t => t.name)
  }

  @computed get filteredTags (): Tag[] {
    return _.flatMap(this.groupedTags, gt => gt.tags).filter(t => t.category === this.selectedCategory)
  }

  private addTag = (url: string, tagId: number) => {
    ApiClientFactory.getInstance()
      .post(`${url}?add=${tagId}`)
      .then(() => this.props.encounterTagsStore.invalidate())
      .catch(err => console.log(err))
  }

  private removeTag = (url: string, tag: Tag) => {
    ApiClientFactory.getInstance()
      .post(`${url}?remove=${tag.id}`)
      .then(() => this.props.encounterTagsStore.invalidate())
      .catch(err => console.log(err))
  }

  @computed get tagSelectOptions () {
    return this.availableTags.map(t => ({ value: String(t.id), label: t.name }))
  }

  @observable private selectedTag: string | null

  render () {
    return <div style={{ marginLeft: 60 }}>
      <LazyStorePanel store={this.props.encounterTagsStore}>
        {
          tags => <>
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: 10 }}>Tags:</div>
              <div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {this.selectedTags.map(t => <Chip
                    key={t.id}
                    label={`${t.name} (${t.category || 'N/A'})`}
                    onDelete={() => this.removeTag(tags.postbackUrl, t)}
                  />)}
                  <div style={{ width: 250, marginLeft: 8, marginRight: 8 }}>
                    <ReactSelectMaterialUi
                      fullWidth={true}
                      placeholder="Add Tag"
                      options={this.tagSelectOptions}
                      value={this.selectedTag}
                      onChange={value => {
                        this.selectedTag = value
                        this.addTag(tags.postbackUrl, value)
                        this.selectedTag = null
                      }}
                    />
                  </div>
                </div>
                <Tabs
                  value={this.selectedCategory}
                  onChange={(ev, newValue) => this._selectedCategory = newValue}
                >
                  {
                    this.groupedTags.map(gt => <Tab key={gt.category} value={gt.category}
                                                    label={gt.category || 'N/A'}/>)
                  }
                </Tabs>
                {
                  this.filteredTags.length
                    ? this.filteredTags.map(t => <Chip
                      key={t.id}
                      variant="outlined"
                      label={t.name}
                      onClick={() => this.addTag(tags.postbackUrl, t.id)}
                    />)
                    : null
                }
              </div>
            </div>
          </>
        }
      </LazyStorePanel>
    </div>
  }
}
