import BaseModel from './BaseModel'
import { Moment } from 'moment'
import GroupedTimelineValues from './GroupedTimelineValues'
import TimelineItemAccount from './TimelineItemAccount'

export default class TimelineItem extends BaseModel {
  id: number
  date: Moment
  type: string
  icon: string
  header: string
  label: string
  groupedValues: GroupedTimelineValues[] = []
  accounts: TimelineItemAccount[] = []

  setGroupedValues (a?: []) {
    return a ? a.map(aa => new GroupedTimelineValues().init(aa)) : []
  }

  setAccounts (a?: []) {
    return a ? a.map(aa => new TimelineItemAccount().init(aa)) : []
  }

  getCasts (): any {
    return {
      id: 'number',
      date: 'datetime',
    }
  }
}