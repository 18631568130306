import React from 'react';
import './App.scss';
import { BrowserRouter as Router } from "react-router-dom"
import { ButtonBase, Collapse, createMuiTheme, Drawer, Grid, Tab, Tabs } from "@material-ui/core"
import { observer } from "mobx-react"
import { computed, observable } from "mobx"
import AlertsDrawer from "./components/drawers/AlertsDrawer"
import ApiClient from "./api/ApiClient"
import AccountsList from "./components/AccountsList"
import { ThemeProvider } from '@material-ui/styles';
import EncounterStore from "./stores/EncounterStore"
import Encounter from "./models/Encounter"
import _ from 'lodash';
import StoreDirectory from "./stores/StoreDirectory"
import CallersComponent from "./components/CallersComponent"
import AgentComponent from "./components/AgentComponent"
import QuickActionsComponent from "./components/QuickActionsComponent"
import LazyStorePanel from "./components/LazyStorePanel"
import QuickAction from "./models/QuickAction"
import ContactsDrawer from "./components/drawers/ContactsDrawer"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import EncounterTagsComponent from "./components/EncounterTagsComponent";
import DynamicStepper from "./components/stepper/DynamicStepper";
import queryString from 'query-string'
import FaqsDrawer from './components/drawers/FaqsDrawer'
import ApiClientFactory from './api/ApiClientFactory'
import ServicesList from './components/ServicesList'
import TimelineComponent from './components/TimelineComponent'
import LinkButton from './components/LinkButton'
import { FilterList } from '@material-ui/icons'
import { MultipleSelect } from 'react-select-material-ui'
import Service from './models/Service'
import Account from './models/Account'
import TransactionsComponent from './components/TransactionsComponent'
import NotesComponent from './components/NotesComponent'
import EncounterCommentsComponent from './components/EncounterCommentsComponent';
import CasesDrawer from './components/drawers/CasesDrawer'

const theme = createMuiTheme({
  palette: {
    primary: { main: '#3b5064' },
    secondary: { main: '#3b5997' },
  },
});

export type AccountFiltersType = {
  accountStatus: string[]
}

export type ServiceFiltersType = {
  accountStatus: string[]
  accounts: string[]
  datesOfService: string[]
  patients: string[]
  responsibleParty: string[]
  exclusions: string[],
}

ApiClientFactory.setInstance(ApiClient)

const quickActionComponents: {[type: string]: (props: any) => React.ReactNode} = {
  'Alerts': p => <AlertsDrawer {...p}/>,
  'Contacts': p => <ContactsDrawer {...p}/>,
  'FAQ': p => <FaqsDrawer {...p}/>,
  'Cases': p => <CasesDrawer {...p}/>
}

@observer
export default class App extends React.Component {
  @observable private showDrawer = false
  @observable private drawerComponent?: React.ReactNode
  @observable private encounterStore?: EncounterStore
  @observable private showActionBar = false
  @observable private encounterId?: string

  @computed get stepperLink () {
    return this.encounterStore && this.encounterStore.data
      ? _.find(this.encounterStore.data.links, l => l.rel === 'stepper')
      : undefined
  }

  @computed get stepperUrl () {
    return this.stepperLink
      ? this.stepperLink.href
      : undefined
  }

  componentDidMount (): void {
    let qs = queryString.parse(window.location.search)
    this.encounterId = qs.id as string | undefined

    this.encounterStore = new EncounterStore(Encounter)
    this.encounterStore.href = `https://stepper.azurewebsites.net/api/encounter/${this.encounterId}`
  }

  private handleQuickAction = (quickAction: QuickAction, url: string) => {
    this.drawerComponent = quickActionComponents[quickAction.type]({
      href: url,
      onCloseClicked: () => this.showDrawer = false,
    })
    this.showDrawer = true
  }

  render () {
    return (
      this.encounterStore
        ? <Router>
          <div className="app-container">
            <ThemeProvider theme={theme}>
              <div className="app-header-bar">
                <div className="app-title">
                  Encounter
                </div>
                <div className="app-locator-id">
                  <LazyStorePanel store={this.encounterStore}>
                    {encounter => <span>Locator ID: {encounter.id}</span>}
                  </LazyStorePanel>
                </div>
              </div>
              <div className="app-info-bar">
                <div className="app-info-caller">
                  {
                    StoreDirectory.callersStore.href
                      ? <CallersComponent callersStore={StoreDirectory.callersStore}/>
                      : null
                  }
                </div>
                <div className="app-info-agent">
                  {
                    StoreDirectory.agentStore.href
                      ? <AgentComponent agentStore={StoreDirectory.agentStore}/>
                      : null
                  }
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <ButtonBase onClick={() => this.showActionBar = !this.showActionBar}>
                    <MoreVertIcon/>
                  </ButtonBase>
                </div>
              </div>
              <Collapse in={this.showActionBar}>
                <div style={{ display: 'flex', padding: 20 }}>
                  <div style={{ width: '60%' }}>
                    {
                      StoreDirectory.encounterTagsStore.href
                        ? <EncounterTagsComponent encounterTagsStore={StoreDirectory.encounterTagsStore}/>
                        : null
                    }
                  </div>
                  <div style={{ flex: 1 }}>
                    <EncounterCommentsComponent/>
                  </div>
                </div>
              </Collapse>
              <div className="app-body">
                <div className="app-left-nav">
                  {
                    StoreDirectory.quickActionsStore.href
                      ? <QuickActionsComponent
                        quickActionStore={StoreDirectory.quickActionsStore}
                        onQuickActionClicked={this.handleQuickAction}
                      />
                      : null
                  }
                </div>
                <div className="app-content">
                  <div>
                    {
                      StoreDirectory.accountsStore.href
                        ? <Home/>
                        : this.stepperUrl
                        ? <Stepper url={this.stepperUrl}/>
                        : null
                    }
                  </div>
                  <Drawer
                    open={this.showDrawer}
                    anchor="right"
                    onBackdropClick={() => this.showDrawer = false}
                  >
                    {this.drawerComponent}
                  </Drawer>
                </div>
              </div>
            </ThemeProvider>
          </div>
        </Router>
        : null
    )
  }
}

@observer
class Home extends React.Component {
  @observable selectedTab: string = "services"
  @observable private showServiceFilters = false

  @observable accountFilters: AccountFiltersType = {
    accountStatus: ['Active'],
  }

  @observable _serviceFilters?: ServiceFiltersType

  @computed
  private get serviceFilters (): ServiceFiltersType {
    return this._serviceFilters || this.defaultServiceFilters
  }

  @computed
  private get defaultServiceFilters (): ServiceFiltersType {
    return {
      accountStatus: ['Active'],
      accounts: [],
      datesOfService: [],
      patients: [],
      responsibleParty: [],
      exclusions: [],
    }
  }

  @computed get accounts () {
    return StoreDirectory.accountsStore.href && StoreDirectory.accountsStore.data
      ? StoreDirectory.accountsStore.data.current
      : []
  }

  @computed get services () {
    return _.flatMap(this.accounts, a => a.services)
  }

  @computed get visibleServices () {
    return this.services.filter(service => this.isServiceVisible(service))
  }

  @computed get visibleServiceIds () {
    return this.visibleServices.map(service => service.id)
  }

  private findAccountForService = (serviceId: number): Account | undefined => {
    return _.find(this.accounts, a => !!_.find(a.services, s => s.id === serviceId))
  }

  private isAccountVisible (account: Account) {
    if (this.serviceFilters.accounts.length) {
      if (this.serviceFilters.accounts.indexOf(String(account.id)) === -1) {
        return false
      }
    }

    if (this.serviceFilters.responsibleParty.length) {
      if (this.serviceFilters.responsibleParty.indexOf(String(account.responsibleParty)) === -1) {
        return false
      }
    }

    return !this.serviceFilters.accountStatus.length || (account.active && this.serviceFilters.accountStatus.indexOf('Active') !== -1) || (!account.active && this.serviceFilters.accountStatus.indexOf('In-Active') !== -1);
  }

  private isServiceVisible (service: Service) {
    const account = this.findAccountForService(service.id)

    if (!account || !this.isAccountVisible(account)) {
      return false
    }

    if (this.serviceFilters.datesOfService.length) {
      if (this.serviceFilters.datesOfService.indexOf(service.dateOfService.format('MM/DD/YYYY')) === -1) {
        return false
      }
    }

    if (this.serviceFilters.patients.length) {
      if (this.serviceFilters.patients.indexOf(service.patientName) === -1) {
        return false
      }
    }

    if (this.serviceFilters.exclusions.length) {
      if (!_.intersection(this.serviceFilters.exclusions, service.exclusions).length) {
        return false
      }
    }

    return true
  }

  private handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    this.selectedTab = newValue
  }

  private renderServicesTab = () => {
    return StoreDirectory.accountsStore.href
      ? <LazyStorePanel store={StoreDirectory.accountsStore}>
        {accounts => <ServicesList
          accounts={accounts.current}
          selectedServiceIds={this.visibleServiceIds}
        />}
      </LazyStorePanel>
      : null
  }

  private renderTimelineTab = () => {
    return <TimelineComponent
      timelineStore={StoreDirectory.timelineStore}
      visibleServices={this.visibleServices}
    />
  }

  private renderTransactionsTab = () => {
    return <TransactionsComponent
      transactionsStore={StoreDirectory.transactionsStore}
      visibleServices={this.visibleServices}
    />
  }

  private renderNotesTab = () => {
    return <NotesComponent
      notesStore={StoreDirectory.notesStore}
    />
  }

  render () {
    return <Grid container spacing={3}>
      <Grid item xs={4}>
        {
          StoreDirectory.accountsStore.href
            ? <AccountsList
              accountsStore={StoreDirectory.accountsStore}
              filters={this.accountFilters}
              onFiltersChanged={(filters) => {
                this.accountFilters = filters
              }}
            />
            : null
        }
      </Grid>
      <Grid item xs={8}>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <Tabs value={this.selectedTab} onChange={this.handleTabChange} aria-label="simple tabs example">
              <Tab value="services" label="Services"/>
              <Tab value="timeline" label="Timeline"/>
              <Tab value="transactions" label="Transactions"/>
              <Tab value="notes" label="Notes"/>
            </Tabs>
          </div>
          <div>
            <LinkButton
              onClick={() => {
                this.showServiceFilters = !this.showServiceFilters
              }}
              className="header-filter-link"
            ><FilterList/></LinkButton>
          </div>
        </div>
        {
          this.showServiceFilters
            ? <div className="filters-list" style={{ marginTop: 10 }}>
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                  <MultipleSelect
                    fullWidth={true}
                    label="Accounts"
                    options={_.orderBy(this.accounts, a => a.id).map(a => String(a.id))}
                    values={this.serviceFilters.accounts}
                    onChange={values => {
                      this._serviceFilters = {
                        ...this.serviceFilters,
                        accounts: values,
                      }
                    }}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <MultipleSelect
                    fullWidth={true}
                    label="Dates of Service"
                    options={_.uniq(_.orderBy(this.services, s => s.dateOfService.format('YYYY-MM-DD')).map(s => s.dateOfService.format('MM/DD/YYYY')))}
                    values={this.serviceFilters.datesOfService}
                    onChange={values => {
                      this._serviceFilters = {
                        ...this.serviceFilters,
                        datesOfService: values,
                      }
                    }}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <MultipleSelect
                    fullWidth={true}
                    label="Patient"
                    options={_.uniq(_.orderBy(this.services, s => s.patientName).map(s => s.patientName))}
                    values={this.serviceFilters.patients}
                    onChange={values => {
                      this._serviceFilters = {
                        ...this.serviceFilters,
                        patients: values,
                      }
                    }}
                  />
                </div>
                <div style={{ flex: 1 }}/>
              </div>
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                  <MultipleSelect
                    fullWidth={true}
                    label="Account Status"
                    options={['Active', 'In-Active']}
                    values={this.serviceFilters.accountStatus}
                    onChange={values => {
                      this._serviceFilters = {
                        ...this.serviceFilters,
                        accountStatus: values,
                      }
                    }}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <MultipleSelect
                    fullWidth={true}
                    label="Exclusions"
                    options={_.uniq(_.flatMap(this.services, s => s.exclusions)).sort()}
                    values={this.serviceFilters.exclusions}
                    onChange={values => {
                      this._serviceFilters = {
                        ...this.serviceFilters,
                        exclusions: values,
                      }
                    }}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <MultipleSelect
                    fullWidth={true}
                    label="Responsible Party"
                    options={_.uniq(_.orderBy(this.accounts, a => a.responsibleParty).map(a => a.responsibleParty))}
                    values={this.serviceFilters.responsibleParty}
                    onChange={values => {
                      this._serviceFilters = {
                        ...this.serviceFilters,
                        responsibleParty: values,
                      }
                    }}
                  />
                </div>
                <div style={{ flex: 1 }}/>
              </div>
            </div>
            : null
        }
        <div style={{ paddingTop: 19 }}>
          {
            this.selectedTab === 'services'
              ? this.renderServicesTab()
              : null
          }

          {
            this.selectedTab === 'timeline'
              ? this.renderTimelineTab()
              : null
          }

          {
            this.selectedTab === 'transactions'
              ? this.renderTransactionsTab()
              : null
          }

          {
            this.selectedTab === 'notes'
              ? this.renderNotesTab()
              : null
          }
        </div>
      </Grid>
    </Grid>
  }
}

type StepperProps = {
  url: string
}

@observer
class Stepper extends React.Component<StepperProps> {
  render () {
    return <div>
      <DynamicStepper
        url={this.props.url}/>
    </div>

  }
}
