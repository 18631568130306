import React from "react";
import { observer } from "mobx-react"
import { observable } from "mobx"
import { Card, CardContent, Grid, LinearProgress } from "@material-ui/core"
import { StepperStepDescriptor } from "../models/StepperStepDescriptor"
import { DynamicStepperProps } from "./DynamicStepperProps"
import { StepperDescriptor } from "../models/StepperDescriptor"
import DStepper from "./DStepper"
import DSummary from "./DSummary"
import { transformApiUrl } from "../../api/ApiClient"
import ApiClientFactory from '../../api/ApiClientFactory'

@observer
export default class DynamicStepper extends React.Component<DynamicStepperProps> {
  @observable private loading = false
  @observable private error?: string

  @observable private descriptor?: StepperDescriptor

  componentDidMount (): void {
    this.loadStepper(this.props.url)
  }

  private loadStepper = async (url: string) => {
    this.loading = true

    try {
      const response = await ApiClientFactory.getInstance()
        .get(url)

      this.descriptor = response.data
    } catch (e) {
      this.error = 'There was an error loading the stepper data'
    }

    this.loading = false
  }

  private handleStepNavigationButton = async (step: StepperStepDescriptor, action: string, postData: any) => {
    if (!step.postbackURL) {
      // TODO: need to handle this?
      return
    }

    this.loading = true

    try {
      const response = await ApiClientFactory.getInstance()
        .post(transformApiUrl(step.postbackURL), postData, {
          headers: {
            'Action': action,
          }
        })

      this.descriptor = response.data
    } catch (e) {
      this.error = 'There was an error loading the stepper data'
    }

    this.loading = false

    // if (step.postbackURL)
    //   if (step.postbackURL === 'https://www.emeraldar.com/api/encounter/658986/stepper/greeting?step=31') {
    //     if (action === 'next') {
    //       this.stepId = 'step-1-2'
    //       this.loadStepper()
    //     }
    //   } else if (step.postbackURL === 'https://www.emeraldar.com/api/encounter/658986/stepper/greeting?step=32') {
    //     if (action === 'back') {
    //       this.stepId = 'step-1-1'
    //       this.loadStepper()
    //     } else if (action === 'next') {
    //       this.stepId = 'step-1-3'
    //       this.loadStepper()
    //     }
    //   } else if (step.postbackURL === 'https://www.emeraldar.com/api/encounter/658986/stepper/greeting?step=33') {
    //     if (action === 'back') {
    //       this.stepId = 'step-1-2'
    //       this.loadStepper()
    //     } else if (action === 'next') {
    //       this.stepId = 'step-2-1'
    //       this.loadStepper()
    //     }
    //   } else if (step.postbackURL === 'https://www.emeraldar.com/api/encounter/658986/stepper/greeting?step=41') {
    //     if (action === 'back') {
    //       this.stepId = 'step-1-3'
    //       this.loadStepper()
    //     } else if (action === 'next') {
    //       this.stepId = 'step-2-2'
    //       this.loadStepper()
    //     }
    //   } else if (step.postbackURL === 'https://www.emeraldar.com/api/encounter/658986/stepper/greeting?step=42') {
    //     if (action === 'back') {
    //       this.stepId = 'step-2-1'
    //       this.loadStepper()
    //     } else if (action === 'next') {
    //       this.stepId = 'step-3-1'
    //       this.loadStepper()
    //     }
    //   } else if (step.postbackURL === 'https://www.emeraldar.com/api/encounter/658986/stepper/greeting?step=21') {
    //     if (action === 'next') {
    //       this.stepId = 'step-2-1'
    //       this.loadStepper()
    //     }
    //   } else if (step.postbackURL === 'https://www.emeraldar.com/api/encounter/658986/stepper/greeting?step=22') {
    //     if (action === 'next') {
    //       this.stepId = 'step-3-1'
    //       this.loadStepper()
    //     } else if (action === 'back') {
    //       this.stepId = 'step-1-1'
    //       this.loadStepper()
    //     }
    //   } else if (step.postbackURL === 'https://www.emeraldar.com/api/encounter/658986/stepper/greeting?step=23') {
    //     if (action === 'back') {
    //       this.stepId = 'step-2-1'
    //       this.loadStepper()
    //     }
    //   }
  }

  private renderLoading = () => {
    return <LinearProgress/>
  }

  private renderError = () => {
    return this.error
  }

  private renderStepper = () => {
    const descriptor = this.descriptor!

    return <Grid container spacing={2}>
      {
        descriptor.summary
          ? <Grid item xs={4}>
            <DSummary summary={descriptor.summary}/>
          </Grid>
          : null
      }
      <Grid item xs={descriptor.summary ? 8 : 12}>
        <Card>
          <CardContent>
            <DStepper
              stepper={descriptor}
              onStepNavigationButtonClicked={this.handleStepNavigationButton}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  }

  render () {
    return <div>{
      (this.loading || (!this.descriptor && !this.error))
        ? this.renderLoading()
        : this.error
        ? this.renderError()
        : this.renderStepper()
    }</div>
  }
}
