import BaseModel from "./BaseModel"
import Link from "./Link"
import Address from "./Address"
import PhoneNumber from "./PhoneNumber"
import AgentExtendedInformation from "./AgentExtendedInformation"

export class Agent extends BaseModel {
  id: number
  name: string
  addressHeader: string
  address: Address
  phonesHeader: string
  phoneNumbers: PhoneNumber[] = []
  extendedInformation: AgentExtendedInformation[] = []
  links: Link[] = []

  setPhoneNumbers (a?: []) {
    return a ? a.map(aa => new PhoneNumber().init(aa)) : []
  }

  setExtendedInformation (a?: []) {
    return a ? a.map(aa => new AgentExtendedInformation().init(aa)) : []
  }

  setLinks (a?: []) {
    return a ? a.map(aa => new Link().init(aa)) : []
  }

  getCasts (): {} {
    return {
      id: 'number',
    }
  }
}
