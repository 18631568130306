import * as React from "react"
import { Step, StepContent, StepLabel, Stepper } from "@material-ui/core"
import { StepperDescriptor, StepperOrientation } from "../models/StepperDescriptor"
import { computed, observable } from "mobx"
import _ from "lodash"
import { StepperStepDescriptor } from "../models/StepperStepDescriptor"
import DStepButtons from "./DStepButtons"
import { observer } from "mobx-react"
import DForm from "./DForm"

type DStepperProps = {
  stepper: StepperDescriptor
  onStepNavigationButtonClicked: (step: StepperStepDescriptor, action: string, postData: any) => void
}

@observer
export default class DStepper extends React.Component<DStepperProps> {
  @observable formData: any = {}

  componentDidMount (): void {
    if (this.activeStep && this.activeStep.content.form) {
      this.formData = this.activeStep.content.form.data
    }
  }

  @computed get activeStep () {
    return _.find<StepperStepDescriptor>(this.props.stepper.steps, s => s.active)
  }

  @computed get activeStepIndex () {
    return _.findIndex(this.props.stepper.steps, s => s.active)
  }

  private handleStepNavigationButton = (step: StepperStepDescriptor, action: string, postData: any) => {
    this.props.onStepNavigationButtonClicked(step, action, this.formData)
  }

  private onFormDataChanged = (formData: any) => {
  }

  private renderStepContent = (step: StepperStepDescriptor) => {
    if (step.content.stepper) {
      return <DStepper
        stepper={step.content.stepper}
        onStepNavigationButtonClicked={this.props.onStepNavigationButtonClicked}
      />
    } else if (step.content.form) {
      return <div>
        <DForm
          onFormDataChanged={this.onFormDataChanged}
          form={step.content.form}
        />
      </div>
    } else {
      return null
    }
  }

  render () {
    const { stepper } = this.props
    const activeStep = this.activeStep

    return <>
      <Stepper orientation={stepper.orientation} activeStep={this.activeStepIndex}>
        {
          stepper.steps.map(step => <Step key={step.id}>
            <StepLabel
              alternativeLabel={stepper.orientation === StepperOrientation.Horizontal}
              completed={step.complete}
            >{step.label}</StepLabel>
            {
              (stepper.orientation === StepperOrientation.Vertical && step.active)
                ? <StepContent>
                  {this.renderStepContent(step)}
                  <DStepButtons
                    stepIndex={this.activeStepIndex}
                    step={step}
                    onNavigationButtonClicked={(action, postData) => this.handleStepNavigationButton(step, action, postData)}
                  />
                </StepContent>
                : null
            }
          </Step>)
        }
      </Stepper>
      {
        (stepper.orientation === StepperOrientation.Horizontal && activeStep)
          ? <>
            {this.renderStepContent(activeStep)}
            <DStepButtons
              stepIndex={this.activeStepIndex}
              buttonVariant="contained"
              step={activeStep}
              onNavigationButtonClicked={(action, postData) => this.handleStepNavigationButton(activeStep, action, postData)}
            />
          </>
          : null
      }
    </>
  }
}
