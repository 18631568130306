import { observer } from "mobx-react"
import React from "react"
import CallersStore from "../stores/CallersStore"
import LazyStorePanel from "./LazyStorePanel"
import { Avatar, Chip } from "@material-ui/core"
import { Caller } from "../models/Caller"
import CheckIcon from '@material-ui/icons/Check'

type Props = {
  callersStore: CallersStore
}

@observer
export default class CallersComponent extends React.Component<Props> {
  private removeCaller = (caller: Caller) => {

  }

  render () {
    return <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ marginRight: 10 }}>Caller:</div>
      <LazyStorePanel store={this.props.callersStore}>
        {
          callers => callers.current.map(caller => <Chip key={caller.id} label={`${caller.name} (${caller.label})`} avatar={<Avatar><CheckIcon/></Avatar>} onDelete={() => this.removeCaller(caller)}/>)
        }
      </LazyStorePanel>
    </div>
  }
}
