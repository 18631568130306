import NotesStore from '../stores/NotesStore'
import React from 'react'
import { computed } from 'mobx'
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Paper } from '@material-ui/core'
import DynamicIcon from './DynamicIcon'

type Props = {
  notesStore: NotesStore
}

export default class NotesComponent extends React.Component<Props> {
  @computed
  private get notes () {
    return this.props.notesStore.data ? this.props.notesStore.data.current : []
  }

  componentDidMount (): void {
    this.props.notesStore.fetch().then()
  }

  render () {
    return <Paper>
      {
        this.notes.length
          ? this.notes.map(note => <ExpansionPanel key={note.id} style={{ marginBottom: 20 }}>
            <ExpansionPanelSummary>
              <DynamicIcon icon={note.icon}/> {note.label} • {note.date.format('MM/DD/YY hh:mma z')}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div dangerouslySetInnerHTML={{ __html: note.text }}/>
            </ExpansionPanelDetails>
          </ExpansionPanel>)
          : <div style={{ padding: 20 }}>There are no notes to display</div>
      }
    </Paper>
  }
}
