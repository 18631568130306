import { observer } from "mobx-react";
import React from "react";
import Service from "../models/Service";
import _ from "lodash";
import { computed } from "mobx";
import { AmountAggregate, computeAmountAggregates } from "../models/AmountAggregate";
import AmountsListComponent from "./AmountsListComponent";

export type Props = {
  services: Service[]
}

@observer
export default class AccountBalanceComponent extends React.Component<Props> {
  @computed get amounts (): AmountAggregate[] {
    return computeAmountAggregates(_.flatMap(this.props.services, s => s.amounts))
  }

  @computed get balance (): number {
    return _.sumBy(this.props.services, s => s.balance)
  }

  render () {
    const { services } = this.props

    return <>
      <AmountsListComponent
        amounts={[
          {
            label: "Total Charges",
            total: _.sumBy(services, s => s.totalCharges),
            index: 0,
          },
          ...this.amounts,
        ]}
        footerLabel="Due by Patient"
        footerAmount={this.balance}
      />
    </>
  }
}