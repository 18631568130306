import BaseModel from "./BaseModel"
import Link from "./Link"

export class Tags extends BaseModel {
  selected: number[] = []
  current: Tag[] = []
  postbackUrl: string
  links: Link[] = []

  setCurrent (a?: []) {
    return a ? a.map(aa => new Tag().init(aa)) : []
  }

  setLinks (a?: []) {
    return a ? a.map(aa => new Link().init(aa)) : []
  }

  setSelected (a?: []) {
    return a ? a.map(aa => Number(aa)) : []
  }
}

export class Tag extends BaseModel {
  id: number
  category: string
  name: string

  getCasts (): {} {
    return {
      id: 'number',
    }
  }
}