import React from "react"
import Account from "../models/Account"
import { observer } from "mobx-react"
import { Checkbox } from "@material-ui/core"
import { formatCurrency } from "../common/Util"
import _ from "lodash";
import ExpansionListItem from './ExpansionListItem'

type Props = {
  account: Account
  selectedServiceIds: number[]
  onSelectedServicesChanged: (selectedServiceIds: number[]) => void
}

@observer
export default class AccountListItem extends React.Component<Props> {
  render () {
    const { account, selectedServiceIds } = this.props

    const header = <div style={{ display: 'flex' }}>
      <div style={{ flex: 1 }}>
        <div style={{ fontSize: 16 }}>
          {account.clientName}
        </div>
        <div style={{ fontSize: 14, color: '#666', paddingTop: 6 }}>
          Ref # {account.reference} {account.responsibleParty}
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ paddingRight: 10 }}>{formatCurrency(account.balance)}</div>
        <Checkbox
          checked={_.filter(account.services, service => selectedServiceIds.indexOf(service.id) > -1).length > 0}
          indeterminate={_.filter(account.services, service => selectedServiceIds.indexOf(service.id) > -1).length > 0 && _.filter(account.services, service => selectedServiceIds.indexOf(service.id) > -1).length < account.services.length}
          onChange={ev => {
            const selected = selectedServiceIds.slice()
            const serviceIds = account.services.map(s => s.id)

            if (ev.target.checked) {
              this.props.onSelectedServicesChanged(selected.filter(sid => serviceIds.indexOf(sid) === -1).concat(serviceIds))
            } else {
              this.props.onSelectedServicesChanged(selected.filter(sid => serviceIds.indexOf(sid) === -1))
            }
          }}
          onClick={ev => ev.stopPropagation()}
        />
      </div>
    </div>

    const content = <div className="account-service-list">
      {
        account.services.map(service => <div key={service.id} className="account-service-list-item">
          <div style={{ flex: 1 }}>
            {service.dateOfService.format('MM/DD/YY')}<br/>
            Svc ID {service.id}<br/>
            Charge Total: {formatCurrency(service.totalCharges)}
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ paddingRight: 10 }}>{formatCurrency(service.balance)}</div>
            <Checkbox
              checked={selectedServiceIds.indexOf(service.id) > -1}
              onClick={ev => ev.stopPropagation()}
              onChange={ev => {
                if (ev.target.checked) {
                  this.props.onSelectedServicesChanged(selectedServiceIds.concat([service.id]))
                } else {
                  this.props.onSelectedServicesChanged(selectedServiceIds.filter(id => id !== service.id))
                }
              }}
            />
          </div>
        </div>)
      }
    </div>

    return <ExpansionListItem
      header={header}
      content={content}
      hideExpandIcon={true}
      className="account-list-item-expansion"
    />
  }
}
