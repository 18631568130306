import React from "react";
import { observer } from "mobx-react";
import { ButtonBase, ClickAwayListener, Grow, List, Paper, Popper } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import Service from "../../models/Service";
import { observable } from "mobx";
import Insurance from "../../models/Insurance";
import AmountsListComponent from "../AmountsListComponent";
import TransactionListComponent from "../TransactionListComponent";
import ClaimListComponent from "../ClaimListComponent";
import ExpansionListItem from '../ExpansionListItem'

type Props = {
  service: Service
  insurance: Insurance
  anchorEl: Element
  onClosed: () => void
}

@observer
export default class InsurancePopover extends React.Component<Props> {
  @observable isOpen = true

  render () {
    const { anchorEl, service, insurance } = this.props

    return <Popper
      anchorEl={anchorEl}
      open={this.isOpen}
      placement="bottom-start"
      transition
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}
              onExited={() => this.props.onClosed()}
              timeout={350}
        >
          <Paper>
            <ClickAwayListener onClickAway={() => this.isOpen = false}>
              <div className="detail-popover">
                <div className="detail-popover-header">
                  <div className="detail-popover-title">
                    <h4>{insurance.extendedInformation.longLabel}</h4>
                    <h5>{insurance.extendedInformation.name}</h5>
                    <h6>{insurance.extendedInformation.subscriberName}</h6>
                    <h6>ID: {insurance.extendedInformation.memberID}</h6>
                    <h6>Group #: {insurance.extendedInformation.group}</h6>
                  </div>
                  <div className="detail-popover-close-button">
                    <ButtonBase onClick={() => this.isOpen = false}>
                      <CancelIcon/>
                    </ButtonBase>
                  </div>
                </div>
                <div className="detail-popover-body">
                  <AmountsListComponent
                    amounts={[
                      {
                        label: "Total Charges",
                        total: service.totalCharges,
                        index: 0,
                      },
                      ...insurance.extendedInformation.amounts,
                    ]}
                    footerLabel="Amount Due"
                    footerAmount={service.balance}
                  />
                  <div style={{ marginTop: 10 }}>
                    <List>
                      <ExpansionListItem header="Transactions" content={
                        <TransactionListComponent
                          transactions={insurance.extendedInformation.transactions}
                          showPayor={false}
                        />
                      }/>

                      <ExpansionListItem header="Claim History" content={
                        <ClaimListComponent
                          claims={insurance.extendedInformation.claims}
                        />
                      }/>
                    </List>
                  </div>
                </div>
              </div>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  }
}