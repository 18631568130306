import * as React from "react"
import { StepperSummarySectionDescriptor } from "../models/StepperSummarySectionDescriptor"
import DSummaryRow from "./DSummaryRow"
import { observer } from "mobx-react"

type Props = {
  summarySection: StepperSummarySectionDescriptor
}

@observer
export default class DSummarySection extends React.Component<Props> {
  render () {
    return <div className="stepper-summary-section">
      {
        this.props.summarySection.header
          ? <h4>{this.props.summarySection.header}</h4>
          : null
      }
      {this.props.summarySection.rows.map((row, idx) => <DSummaryRow key={idx} summaryRow={row}/>)}
    </div>
  }
}