import BaseModel from "./BaseModel"
import Link from "./Link"
import Amount from "./Amount"
import Service from "./Service"

export default class Account extends BaseModel {
  id: number
  reference: string
  responsibleParty: string
  clientName: string
  active: boolean
  links: Link[]
  balance: number
  fees: Amount[]
  services: Service[]

  setLinks (a?: []) {
    return a ? a.map(aa => new Link().init(aa)) : []
  }

  setFees (a?: []) {
    return a ? a.map(aa => new Amount().init(aa)) : []
  }

  setServices (a?: []) {
    return a ? a.map(aa => new Service().init(aa)) : []
  }

  getCasts (): {} {
    return {
      id: 'number',
      active: 'boolean',
      balance: 'number',
    }
  }
}
