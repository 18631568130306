import { observer } from 'mobx-react'
import React, { ReactNode } from 'react'
import Announcement from '@material-ui/icons/Announcement'
import People from '@material-ui/icons/People'
import QuestionAnswer from '@material-ui/icons/QuestionAnswer'
import NextWeek from '@material-ui/icons/NextWeek'
import PersonAdd from '@material-ui/icons/PersonAdd'
import { IconProps } from '@material-ui/core/Icon'
import { AddBox, AttachMoney, Mail, Update, Work } from '@material-ui/icons'

const iconMap: {[key: string]: (props: any) => ReactNode} = {
  'Announcement': p => <Announcement {...p} />,
  'People': p => <People {...p}/>,
  'NextWeek': p => <NextWeek {...p}/>,
  'QuestionAnswer': p => <QuestionAnswer {...p}/>,
  'PersonAdd': p => <PersonAdd {...p}/>,
  'Work': p => <Work {...p} />,
  'AttachMoney': p => <AttachMoney {...p} />,
  'Mail': p => <Mail {...p} />,
  'Update': p => <Update {...p} />,
  'AddBox': p => <AddBox {...p} />,
}

type Props = {
  icon: string
}

@observer
export default class DynamicIcon extends React.Component<Props & IconProps> {
  render () {
    const { icon, ...rest } = this.props

    if (!iconMap[this.props.icon]) {
      console.log(`Unknown DynamicIcon: "${this.props.icon}"`)
    }

    return iconMap[this.props.icon] ? iconMap[this.props.icon](rest) : null
  }
}
