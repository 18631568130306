import React from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { Collapse, ListItem, ListItemText } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'

type Props = {
  header: React.ReactNode
  content: React.ReactNode
  hideExpandIcon: boolean
  className?: string
}

@observer
export default class ExpansionListItem extends React.Component<Props> {
  static defaultProps = {
    hideExpandIcon: false,
  }

  @observable private isOpen = false

  private toggle = () => {
    this.isOpen = !this.isOpen
  }

  render () {
    return <>
      <ListItem
        button
        onClick={this.toggle}
        className={this.props.className}
      >
        <ListItemText>
          {this.props.header}
        </ListItemText>
        {
          !this.props.hideExpandIcon
            ? (this.isOpen ? <ExpandLess/> : <ExpandMore/>)
            : null
        }
      </ListItem>
      <Collapse in={this.isOpen} unmountOnExit>
        {this.props.content}
      </Collapse>
    </>
  }
}