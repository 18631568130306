import React from "react";
import { observer } from "mobx-react";
import { ButtonBase, ClickAwayListener, Grow, Paper, Popper, Tab, Tabs } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import Service from "../../models/Service";
import { observable } from "mobx";
import AccountBalanceComponent from "../AccountBalanceComponent";
import TransactionListComponent from "../TransactionListComponent";

type Props = {
  service: Service
  anchorEl: Element
  onClosed: () => void
}

@observer
export default class DateOfServicePopover extends React.Component<Props> {
  @observable isOpen = true

  @observable activeTab = 'medical'

  private handleTabChange = (ev: React.ChangeEvent<{}>, newValue: string) => {
    this.activeTab = newValue
  }

  private renderMedicalTab = (service: Service) => {
    return <div>
      <b>Physicians</b>
      <ul>
        {service.physicians.map((physicianName, idx) => <li key={idx}>{physicianName}</li>)}
      </ul>
      <hr/>
      <b>Services</b>
      <ul>
        {service.services.map((serviceName, idx) => <li key={idx}>{serviceName}</li>)}
      </ul>
      <hr/>
      <AccountBalanceComponent services={[service]}/>
    </div>
  }

  private renderTransactionsTab = (service: Service) => {
    return <TransactionListComponent
      transactions={service.transactions}
    />
  }

  render () {
    const { anchorEl, service } = this.props
    const activeTab = this.activeTab

    return <Popper
      anchorEl={anchorEl}
      open={this.isOpen}
      placement="bottom-start"
      transition
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}
              onExited={() => this.props.onClosed()}
              timeout={350}
        >
          <Paper>
            <ClickAwayListener onClickAway={() => this.isOpen = false}>
              <div className="detail-popover">
                <div className="detail-popover-header">
                  <div className="detail-popover-title">
                    <h4>Date of Service - {service.dateOfService.format('MM/DD/YYYY')}</h4>
                    <h5>{service.facility}</h5>
                    <h6>Patient: {service.patientName}</h6>
                  </div>
                  <div className="detail-popover-close-button">
                    <ButtonBase onClick={() => this.isOpen = false}>
                      <CancelIcon/>
                    </ButtonBase>
                  </div>
                </div>
                <div className="detail-popover-body" style={{ paddingTop: 0 }}>
                  <Tabs value={activeTab} onChange={this.handleTabChange}>
                    <Tab value="medical" label="Medical"/>
                    <Tab value="transactions" label="Transactions"/>
                  </Tabs>
                  <div style={{ marginTop: 10 }}>
                    {
                      activeTab === 'medical'
                        ? this.renderMedicalTab(service)
                        : null
                    }
                    {
                      activeTab === 'transactions'
                        ? this.renderTransactionsTab(service)
                        : null
                    }
                  </div>
                </div>
              </div>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  }
}