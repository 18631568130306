import BaseModel from './BaseModel'
import { Moment } from 'moment'

export default class SimpleTransaction extends BaseModel {
  id: number
  date: Moment
  type: string
  amount: number
  payor: string

  getCasts (): {} {
    return {
      id: 'number',
      date: 'datetime',
      amount: 'number',
    }
  }
}
