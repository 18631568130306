import React from "react";
import { observer } from "mobx-react";
import { ButtonBase, ClickAwayListener, Grow, Paper, Popper } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { observable } from "mobx";
import { Agent } from '../../models/Agent'

type Props = {
  agent: Agent
  anchorEl: Element
  onClosed: () => void
}

@observer
export default class AgentForPopover extends React.Component<Props> {
  @observable isOpen = true

  render () {
    const { anchorEl, agent } = this.props

    return <Popper
      anchorEl={anchorEl}
      open={this.isOpen}
      placement="bottom-start"
      transition
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}
              onExited={() => this.props.onClosed()}
              timeout={350}
        >
          <Paper>
            <ClickAwayListener onClickAway={() => this.isOpen = false}>
              <div className="detail-popover">
                <div className="detail-popover-header">
                  <div className="detail-popover-title">
                    <h4>{agent.name}</h4>
                    <h5>{agent.id}</h5>
                  </div>
                  <div className="detail-popover-close-button">
                    <ButtonBase onClick={() => this.isOpen = false}>
                      <CancelIcon/>
                    </ButtonBase>
                  </div>
                </div>
                <div className="detail-popover-body">
                  <div>
                    Mail Payments to:
                    <div style={{ marginLeft: 10 }}>
                      {agent.address.line1}<br/>
                      {agent.address.line2}<br/>
                      {agent.address.city}, {agent.address.state} {agent.address.zip}
                    </div>
                  </div>
                  {
                    agent.phoneNumbers.length
                      ? <div>
                        Phone Numbers:
                        <div style={{ marginLeft: 10 }}>
                          {
                            agent.phoneNumbers.map((phoneNumber, idx) => <span key={idx}>{phoneNumber.type}: {phoneNumber.number}</span>)
                          }
                        </div>
                      </div>
                      : null
                  }
                  {
                    agent.extendedInformation.length
                      ? agent.extendedInformation.map((ext, index) => <div key={index}>
                        {ext.label}: {ext.value}
                      </div>)
                      : null
                  }
                </div>
              </div>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  }
}