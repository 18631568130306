import BaseModel from "./BaseModel"
import QuickAction from "./QuickAction"
import Link from "./Link"

export default class QuickActions extends BaseModel {
  current: QuickAction[] = []
  links: Link[] = []

  setAvailable (a?: []) {
    return a ? a.map(aa => new QuickAction().init(aa)) : []
  }

  setLinks (a?: []) {
    return a ? a.map(aa => new Link().init(aa)) : []
  }
}
