import { observer } from "mobx-react"
import React, { ChangeEvent } from "react"
import { computed, observable } from "mobx";
import { Button, TextField } from "@material-ui/core";
import StoreDirectory from '../stores/StoreDirectory'
import ApiClientFactory from '../api/ApiClientFactory'

type Props = {}

@observer
export default class EncounterCommentsComponent extends React.Component<Props> {
  @observable private value = ''
  @observable submitting = false

  private handleChange = (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    this.value = ev.target.value
  }

  private submitComment = (url: string) => {
    if (this.value.trim().length > 0) {
      this.submitting = true
      ApiClientFactory.getInstance().post(url, JSON.stringify(this.value))
        .then(response => {
          this.value = ''
        })
        .catch(err => console.log(err))
        .then(() => this.submitting = false)
    }
  }

  @computed get postUrl () {
    return StoreDirectory.commentsStore ? StoreDirectory.commentsStore.href : undefined
  }

  render () {
    return <div style={{ display: 'flex' }}>
      <div style={{ marginRight: 10 }}>Note:</div>
      <div style={{ flex: 1, marginRight: 20 }}>
        <TextField
          id="standard-multiline-flexible"
          placeholder="Add Note"
          multiline
          rowsMax="4"
          value={this.value}
          onChange={this.handleChange}
          style={{ width: '100%' }}
          disabled={this.submitting}
        />
        <div style={{ textAlign: 'right' }}>
          <Button
            onClick={() => {
              this.postUrl && this.submitComment(this.postUrl)
            }}
            disabled={!this.postUrl || !this.value.trim().length || this.submitting}
          >Submit</Button>
        </div>
      </div>
    </div>
  }
}
