import React from "react";
import { observer } from "mobx-react";
import { ButtonBase, ClickAwayListener, Grow, Paper, Popper } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { formatCurrency } from "../../common/Util";
import Service from "../../models/Service";
import { computed, observable } from "mobx";
import _ from "lodash";
import { AmountAggregate, computeAmountAggregates } from "../../models/AmountAggregate";
import AccountBalanceComponent from "../AccountBalanceComponent";

type Props = {
  services: Service[]
  anchorEl: Element
  onClosed: () => void
  showDueByPatientBreakDown: boolean
}

@observer
export default class AccountBalancePopover extends React.Component<Props> {
  static defaultProps = {
    showDueByPatientBreakDown: true,
  }

  @observable isOpen = true

  @computed get totalCharges (): number {
    return _.sumBy(this.props.services, s => s.totalCharges)
  }

  @computed get balance (): number {
    return _.sumBy(this.props.services, s => s.balance)
  }

  @computed get balances (): AmountAggregate[] {
    return computeAmountAggregates(_.flatMap(this.props.services, s => s.balances))
  }

  @computed get fees (): AmountAggregate[] {
    return computeAmountAggregates(_.flatMap(this.props.services, s => s.fees))
  }

  @computed get total () {
    return _.sumBy(this.fees, f => f.total) + this.balance
  }

  render () {
    const { anchorEl, services } = this.props

    return <Popper
      anchorEl={anchorEl}
      open={this.isOpen}
      placement="bottom-start"
      transition
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}
              onExited={() => this.props.onClosed()}
              timeout={350}
        >
          <Paper>
            <ClickAwayListener onClickAway={() => this.isOpen = false}>
              <div className="detail-popover">
                <div className="detail-popover-header">
                  <div className="detail-popover-title">
                    <h4>Account Balance</h4>
                    <h5>Service Totals</h5>
                  </div>
                  <div className="detail-popover-close-button">
                    <ButtonBase onClick={() => this.isOpen = false}>
                      <CancelIcon/>
                    </ButtonBase>
                  </div>
                </div>
                <div className="detail-popover-body">
                  <AccountBalanceComponent services={services}/>

                  {
                    this.props.showDueByPatientBreakDown
                      ? <>
                        <hr/>

                        <div style={{ marginBottom: 10 }}>
                          Due by Patient Breakdown
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div style={{ flex: 1, fontWeight: 'bold' }}>
                            Total Due
                          </div>
                          <div style={{ textAlign: 'right' }}>
                            {formatCurrency(this.balance)}
                          </div>
                        </div>

                        {
                          this.balances.map(b => <div key={b.label} style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ flex: 1 }}>
                              {b.label}
                            </div>
                            <div style={{ textAlign: 'right' }}>
                              {formatCurrency(b.total)}
                            </div>
                          </div>)
                        }
                      </>
                      : null
                  }
                </div>
              </div>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  }
}