import * as React from "react"
import { observer } from "mobx-react"
import CloseIcon from '@material-ui/icons/Close'
import { Button, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, TextField } from "@material-ui/core"
import { computed, observable } from "mobx"
import LazyStorePanel from "../LazyStorePanel"
import CasesStore from '../../stores/CasesStore'
import DynamicIcon from '../DynamicIcon'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

type Props = {
  href: string
  onCloseClicked: () => {}
}

@observer
export default class CasesDrawer extends React.Component<Props> {
  @observable private casesStore?: CasesStore

  @observable editCaseId?: number

  @computed get isEditing () {
    return !!this.editCaseId
  }

  componentDidMount (): void {
    const casesStore = new CasesStore()
    casesStore.href = this.props.href

    this.casesStore = casesStore
  }

  editCase = (id: number) => {
    this.editCaseId = id
  }

  cancelEditCase = () => {
    this.editCaseId = undefined
  }

  updateCase = () => {

  }

  render () {
    return <div className="app-drawer">
      <div className="app-drawer-header">
        <h2>Cases</h2>
        <div
          className="close-button"
          onClick={(ev) => {
            ev.preventDefault()
            this.props.onCloseClicked()
          }}
        ><CloseIcon/></div>
      </div>
      <div className="app-drawer-content">
        {
          this.casesStore
            ? <LazyStorePanel
              store={this.casesStore}>
              {cases => cases.current.map(thisCase =>
                <div key={thisCase.id} className="cases-case">
                  <div style={{ display: 'flex' }} className="cases-case-header">
                    <div style={{ flex: 1 }}>
                      {thisCase.date.format('ddd, MMMM D, YYYY')}
                    </div>
                    <div>
                      Case ID: {thisCase.id}
                    </div>
                  </div>
                  <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                      <DynamicIcon icon={thisCase.icon}/>
                      <div style={{ display: 'flex' }}>
                        <div>
                          {thisCase.label} • {thisCase.age} days
                        </div>
                        <div style={{ marginLeft: 24 }}>
                          <b style={{ marginRight: 8 }}>Status</b>
                          {thisCase.details.status.status}
                        </div>
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                        {
                          thisCase.details.groupedValues.map((groupedValues, idx) => <div key={idx}>
                            <div><b>{groupedValues.group}</b></div>
                            <div style={{ marginLeft: 30 }}>
                              <table className="timeline-values-table">
                                <tbody>
                                {groupedValues.values.map((v, idx) => <tr key={idx}>
                                  <td style={{ width: 150 }}><b>{v.label}</b></td>
                                  <td>
                                    {
                                      (this.editCaseId === thisCase.id && v.editable)
                                        ? <TextField
                                          value={v.value}
                                          fullWidth
                                        />
                                        : <div dangerouslySetInnerHTML={{ __html: v.value }}/>
                                    }
                                  </td>
                                </tr>)}
                                </tbody>
                              </table>
                            </div>
                          </div>)
                        }
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                          {
                            this.editCaseId === thisCase.id
                              ? <>
                                <Button
                                  type="button"
                                  onClick={() => this.cancelEditCase()}
                                >Cancel</Button>
                                <Button
                                  type="button"
                                  onClick={() => this.updateCase()}
                                >Submit</Button>
                              </>
                              : <Button
                                type="button"
                                onClick={() => this.editCase(thisCase.id)}
                              >Update</Button>
                          }
                        </div>
                        <div>
                          {
                            thisCase.points.map((casePoint, idx) => <div key={idx} style={{ marginTop: 10 }}>
                              <ExpansionPanel>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                                  <DynamicIcon icon={casePoint.icon}/>
                                  {casePoint.label} • {casePoint.age} days
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {
                                      casePoint.groupedValues.map((groupedValues, idx) => <div key={idx}>
                                        <div><b>{groupedValues.group}</b></div>
                                        <div style={{ marginLeft: 30 }}>
                                          <table className="timeline-values-table">
                                            <tbody>
                                            {groupedValues.values.map((v, idx) => <tr key={idx}>
                                              <td style={{ width: 150 }}><b>{v.label}</b></td>
                                              <td>
                                                <div dangerouslySetInnerHTML={{ __html: v.value }}/>
                                              </td>
                                            </tr>)}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>)
                                    }
                                  </div>
                                </ExpansionPanelDetails>
                              </ExpansionPanel>
                            </div>)
                          }
                        </div>
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  {
                    (this.isEditing && this.editCaseId !== thisCase.id)
                      ? <div className="case-edit-overlay"/>
                      : null
                  }
                </div>
              )
              }
            </LazyStorePanel>
            : null
        }
      </div>
    </div>
  }
}
