import CallersStore from "./CallersStore"
import { observable } from "mobx"
import AgentStore from "./AgentStore"
import QuickActionsStore from "./QuickActionsStore"
import AccountsStore from "./AccountsStore"
import EncounterTagsStore from "./EncounterTagsStore";
import NotesStore from './NotesStore'
import DynamicStore from './DynamicStore'
import Link from '../models/Link'
import TimelineStore from './TimelineStore'
import TransactionsStore from './TransactionsStore'
import CommentsStore from './CommentsStore'

const storeMap = {
  callers: 'callersStore',
  agent: 'agentStore',
  tags: 'encounterTagsStore',
  notes: 'notesStore',
  timeline: 'timelineStore',
  quickactions: 'quickActionsStore',
  accounts: 'accountsStore',
  transactions: 'transactionsStore',
  comments: 'commentsStore',
}

class StoreDirectory {
  @observable callersStore = new CallersStore()
  @observable agentStore = new AgentStore()
  @observable encounterTagsStore = new EncounterTagsStore()
  @observable notesStore = new NotesStore()
  @observable quickActionsStore = new QuickActionsStore()
  @observable accountsStore = new AccountsStore()
  @observable timelineStore = new TimelineStore()
  @observable transactionsStore = new TransactionsStore()
  @observable commentsStore = new CommentsStore()

  populateStores = (links: Link[]) => {
    links.forEach(link => {
      // @ts-ignore
      if (storeMap.hasOwnProperty(link.rel) && this.hasOwnProperty(storeMap[link.rel])) {
        // @ts-ignore
        (this[storeMap[link.rel]] as DynamicStore<any>).href = link.href
      }
    })
  }
}

export default new StoreDirectory()
