import BaseModel from './BaseModel'
import Link from './Link'
import { Moment } from 'moment'
import Phone from './Phone'
import Address from './Address'

export class Contacts extends BaseModel {
  current: Contact[] = []
  links: Link[] = []

  setCurrent (a?: []) {
    return a ? a.map(aa => new Contact().init(aa)) : []
  }

  setLinks (a?: []) {
    return a ? a.map(aa => new Link().init(aa)) : []
  }
}

export class Contact extends BaseModel {
  id: number
  type: 'Authorized Contact' | 'Responsible Party' | 'Patient'
  name: string
  ssn: string
  dob: Moment
  phones: Phone[]
  address: Address
  email: string
  extendedInformation: ContactExtendedInformation
  accounts: ContactAccount[]

  getCasts (): any {
    return {
      id: 'number',
      dob: 'datetime',
    }
  }

  setPhones (a?: []) {
    return a ? a.map(aa => new Phone().init(aa)) : []
  }

  setAddress (a?: {}) {
    return a ? new Address().init(a) : undefined
  }

  setExtendedInformation (a?: {}) {
    return a ? new ContactExtendedInformation().init(a) : undefined
  }

  setAccounts (a?: []) {
    return a ? a.map(aa => new ContactAccount().init(aa)) : []
  }
}

export class ContactExtendedInformation extends BaseModel {
  language: string
  paperlessBilling: boolean

  getCasts (): any {
    return {
      paperlessBilling: 'boolean',
    }
  }
}

export class ContactAccount extends BaseModel {
  id: number
  clientName: string
  reference: string
  responsibleParty: string
  responsiblePartyID: string
  services: ContactService[]

  getCasts (): any {
    return {
      id: 'number',
    }
  }

  setServices (a?: []) {
    return a ? a.map(aa => new ContactService().init(aa)) : []
  }
}

export class ContactService extends BaseModel {
  id: number
  reference: string
  patientName: string
  patentId: number
  balance: number
  active: boolean

  getCasts (): any {
    return {
      id: 'number',
      balance: 'number',
      active: 'boolean',
    }
  }
}
