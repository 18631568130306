import BaseModel from "./BaseModel"
import Link from "./Link"

export default class Encounter extends BaseModel {
  id: number
  links: Link[] = []

  setLinks (a?: []) {
    return a ? a.map(aa => new Link().init(aa)) : []
  }

  getCasts (): {} {
    return {
      id: 'number',
    }
  }
}
