import * as React from "react"
import { RowAlertType, RowType, StepperSummarySectionRowDescriptor, SummaryRowKeyValueData } from "../models/StepperSummarySectionRowDescriptor"
import { observer } from "mobx-react"
import classNames from 'classnames'
import DAccountCard from "./DAccountCard"

type Props = {
  summaryRow: StepperSummarySectionRowDescriptor
}

@observer
export default class DSummaryRow extends React.Component<Props> {
  render () {
    if (this.props.summaryRow.type === RowType.KeyValue) {
      return <KeyValueRowType alert={this.props.summaryRow.alert} data={this.props.summaryRow.value}/>
    } else if (this.props.summaryRow.type === RowType.AccountCard) {
      return <DAccountCard {...this.props.summaryRow.value} />
    } else {
      return null
    }
  }
}

type KeyValueRowTypeProps = {
  alert: RowAlertType
  data: SummaryRowKeyValueData
}

@observer
class KeyValueRowType extends React.Component<KeyValueRowTypeProps> {
  render () {
    return <div className={classNames(['stepper-summary-row stepper-summary-key-value-row', this.props.alert ? `alert-${this.props.alert}` : undefined])}>
      <div className="stepper-summary-key-value-row-key">{this.props.data.key}</div>
      <div className="stepper-summary-key-value-row-value">{this.props.data.value}</div>
    </div>
  }
}