import BaseModel from "./BaseModel"

export default class QuickAction extends BaseModel {
  type: string
  icon: string
  label: string
  badgeCount: number
  rel: string
  id: number

  getCasts (): any {
    return {
      id: 'number',
      badgeCount: 'number',
    }
  }
}
