import BaseModel from "./BaseModel"
import { Moment } from "moment"
import TimelineValue from './TimelineValue'
import Link from './Link'

export class Transactions extends BaseModel {
  current: Transaction[] = []
  links: Link[] = []

  setCurrent (a?: []) {
    return a ? a.map(aa => new Transaction().init(aa)) : []
  }

  setLinks (a?: []) {
    return a ? a.map(aa => new Link().init(aa)) : []
  }
}

export class Transaction extends BaseModel {
  id: number
  date: Moment
  type: string
  reference: string
  description: string
  amount: number
  status: TransactionStatus
  groupedValues: GroupedTransactionValues
  accounts: TransactionAccount[]

  getCasts (): {} {
    return {
      id: 'number',
      date: 'datetime',
      amount: 'number',
    }
  }
}

export class TransactionStatus extends BaseModel {
  status: string
  complete: boolean

  getCasts (): any {
    return {
      complete: 'boolean',
    }
  }
}

export class GroupedTransactionValues extends BaseModel {
  group: string
  values: TimelineValue[]

  setValues (a?: []) {
    return a ? a.map(aa => new TimelineValue().init(aa)) : []
  }
}

export class TransactionAccount extends BaseModel {
  id: number
  reference: string
  clientName: string
  services: TransactionService[]

  getCasts (): any {
    return {
      id: 'number',
    }
  }

  setServices (a?: []) {
    return a ? a.map(aa => new TransactionService().init(aa)) : []
  }

}

export class TransactionService extends BaseModel {
  id: number
  reference: string
  dateOfService: Moment
  allocation: number

  getCasts (): any {
    return {
      id: 'number',
      dateOfService: 'datetime',
      allocation: 'number',
    }
  }
}
