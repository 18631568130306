import { observer } from "mobx-react";
import React from "react";
import classNames from "classnames";

type Props = {
  className?: string
  onClick: (ev: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
}

@observer
export default class LinkButton extends React.Component<Props> {
  onClick = (ev: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    this.props.onClick(ev)
  }

  render () {
    return <span
      className={classNames(this.props.className, "link-button")}
      onClick={this.onClick}
    >{this.props.children}</span>
  }
}