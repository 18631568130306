import React from "react"
import Service from "../models/Service"
import { formatCurrency } from "../common/Util"
import CheckIcon from '@material-ui/icons/Check'
import WarningIcon from '@material-ui/icons/Warning'
import { observable } from "mobx";
import { observer } from "mobx-react";
import DateOfServicePopover from "./popovers/DateOfServicePopover";
import AccountBalancePopover from "./popovers/AccountBalancePopover";
import LinkButton from "./LinkButton";
import InsurancePopover from "./popovers/InsurancePopover";
import Insurance from "../models/Insurance";

type Props = {
  service: Service
}

@observer
export default class ServiceListItem extends React.Component<Props> {
  @observable private accountBalanceEl?: Element
  @observable private insuranceDetail?: {
    el: Element
    insurance: Insurance
  }

  @observable private dateOfServiceEl?: Element

  private alertIcon (alert?: number) {
    if (alert === 0) {
      return <div className="insurance-icon" style={{ color: "#090" }}><CheckIcon className="check-icon"/></div>
    } else if (alert === 1) {
      return <div className="insurance-icon"><WarningIcon className="warning-icon" color="error"/></div>
    } else {
      return null
    }
  }

  render () {
    const { service } = this.props

    return <div className="service-list-item">
      <div style={{ flex: 1 }}>
        <LinkButton onClick={ev => {
          this.dateOfServiceEl = ev.currentTarget
        }}>{service.dateOfService.format('MM/DD/YYYY')}</LinkButton>
        <br/>
        {service.facility}
      </div>
      <div style={{ flex: 1, paddingLeft: 20 }}>
        {
          service.insurances.map(insurance =>
            insurance.extendedInformation
              ? <LinkButton
                key={insurance.id}
                onClick={ev => this.insuranceDetail = { el: ev.currentTarget, insurance: insurance }}>
                <div className="service-insurance">
                  {this.alertIcon(insurance.alert)}
                  {insurance.shortLabel}
                  {
                    insurance.description
                      ? ` • ${insurance.description}`
                      : null
                  }
                </div>
              </LinkButton>
              : <div
                key={insurance.id}
                className="service-insurance"
              >
                {this.alertIcon(insurance.alert)}
                {insurance.shortLabel}
                {
                  insurance.description
                    ? ` • ${insurance.description}`
                    : null
                }
              </div>)
        }
      </div>
      <div style={{ paddingLeft: 20, textAlign: 'center' }}>
        <b>Total Charges</b><br/>
        {formatCurrency(service.totalCharges)}
      </div>
      <div style={{ paddingLeft: 20, textAlign: 'center' }}>
        <b>Due by Patient</b><br/>
        <LinkButton
          onClick={ev => this.accountBalanceEl = ev.currentTarget}>{formatCurrency(service.balance)}</LinkButton>
      </div>

      {
        this.accountBalanceEl
          ? <AccountBalancePopover
            services={[service]}
            anchorEl={this.accountBalanceEl}
            onClosed={() => this.accountBalanceEl = undefined}
          />
          : null
      }


      {
        this.dateOfServiceEl
          ? <DateOfServicePopover
            service={service}
            anchorEl={this.dateOfServiceEl}
            onClosed={() => this.dateOfServiceEl = undefined}
          />
          : null
      }

      {
        this.insuranceDetail
          ? <InsurancePopover
            service={service}
            insurance={this.insuranceDetail.insurance}
            anchorEl={this.insuranceDetail.el}
            onClosed={() => this.insuranceDetail = undefined}
          />
          : null
      }
    </div>
  }
}
