import React from "react";
import { observer } from "mobx-react";
import Claim from "../models/Claim";

type Props = {
  claims: Claim[]
}

@observer
export default class ClaimListComponent extends React.Component<Props> {
  static defaultProps = {
    showPayor: true,
  }

  render () {
    return <div className="item-list">
      {
        this.props.claims.map((claim, idx) => <div key={idx} className="list-item">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ minWidth: 100 }}>{claim.date.format('MM/DD/YYYY')}</div>
            <div style={{ flex: 1 }}>{claim.description}</div>
          </div>
        </div>)
      }
    </div>
  }
}