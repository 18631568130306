import BaseModel from "./BaseModel"
import Link from "./Link"

export class Callers extends BaseModel {
  current: Caller[] = []
  links: Link[] = []

  setCurrent (a?: []) {
    return a ? a.map(aa => new Caller().init(aa)) : []
  }

  setLinks (a?: []) {
    return a ? a.map(aa => new Link().init(aa)) : []
  }
}

export class Caller extends BaseModel {
  id: number
  authenticated: boolean
  name: string
  label: string
  extendedInformation: CallerExtendedInformation[]
  postbackUrl: string

  getCasts (): {} {
    return {
      id: 'number',
      authenticated: 'boolean',
    }
  }

  setExtendedInformation (a?: []) {
    return a ? a.map(aa => new CallerExtendedInformation().init(aa)) : []
  }
}

export class CallerExtendedInformation extends BaseModel {
  label: string
  value: string
}
