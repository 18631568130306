import { observable } from "mobx"
import BaseModel from "../models/BaseModel"
import ApiClientFactory from '../api/ApiClientFactory'

export default class DynamicStore<T extends BaseModel> {
  @observable private _href: string
  @observable private _data?: T = undefined
  @observable private _fetched: boolean = false
  @observable private _invalidated: boolean = false
  @observable private _error?: string
  @observable private _fetching: boolean = false

  constructor (private modelClass: new () => T) {
  }

  get data () {
    return this._data
  }

  get fetched () {
    return this._fetched
  }

  get invalidated () {
    return this._invalidated
  }

  get error () {
    return this._error
  }

  get href () {
    return this._href
  }

  get fetching () {
    return this._fetching
  }

  set href (href: string) {
    this._href = href
    this.invalidate()
  }

  invalidate = () => {
    if (this._fetched) {
      this._invalidated = true
    }
  }

  fetch = (): Promise<T | any> => {
    if (!this._fetched || this._invalidated) {
      this._fetching = true

      return ApiClientFactory.getInstance()
        .get(this._href)
        .then(response => {
          this._fetched = true
          this._invalidated = false
          this._error = undefined
          this._data = new this.modelClass().init(response.data)
          this._fetching = false

          return this._data
        })
        .catch(error => {
          console.log(error)
          this._error = 'ERROR'
          this._fetching = false
        })
    } else {
      return new Promise<T>(resolve => resolve(this._data))
    }
  }
}
