import BaseModel from './BaseModel'

export default class TimelineItemService extends BaseModel {
  id: number

  getCasts (): any {
    return {
      id: 'number',
    }
  }
}