import React from "react"
import { observer } from "mobx-react"
import { autorun, computed, observable } from "mobx"
import Account from '../models/Account'
import { List, Paper } from "@material-ui/core"
import AccountListItem from "./AccountListItem"
import AccountsStore from "../stores/AccountsStore"
import _ from "lodash";
import AccountTotals from "./AccountTotals";
import LinkButton from './LinkButton'
import { FilterList } from '@material-ui/icons'
import { MultipleSelect } from 'react-select-material-ui'
import { AccountFiltersType } from '../App'

type Props = {
  accountsStore: AccountsStore
  onFiltersChanged: (filters: AccountFiltersType) => void
  filters: AccountFiltersType
}

@observer
export default class AccountsList extends React.Component<Props> {
  @observable accounts: Account[] = []
  @observable showFilters = false
  @observable _selectedServiceIds?: number[]

  @computed get selectedServiceIds () {
    return this._selectedServiceIds
      || _.flatMap(this.visibleAccounts, account => account.services.map(service => service.id))
  }

  @computed get services () {
    return this.visibleAccounts ? _.flatMap(this.accounts, a => a.services) : []
  }

  @computed get visibleAccounts () {
    return this.accounts.filter(account => !this.props.filters.accountStatus.length || (account.active && this.props.filters.accountStatus.indexOf('Active') !== -1) || (!account.active && this.props.filters.accountStatus.indexOf('In-Active') !== -1))
  }

  @computed get visibleSelectedServiceIds () {
    return this.selectedServiceIds.filter(serviceId => {
      return !!this.findAccountForService(serviceId, true)
    })
  }

  componentDidMount (): void {
    autorun(() => {
      this.accounts = this.props.accountsStore.data ? this.props.accountsStore.data.current : []
    })

    this.props.accountsStore.fetch().then()
  }

  findAccountForService = (serviceId: number, onlyVisible: boolean = false): Account | undefined => {
    const accounts = onlyVisible ? this.visibleAccounts : this.accounts
    return accounts ? _.find(accounts, a => !!_.find(a.services, s => s.id === serviceId)) : undefined
  }

  handleServiceSelection = (account: Account, selectedServiceIds: number[]) => {
    this._selectedServiceIds = selectedServiceIds
  }

  render () {
    return <div>
      <h2>
        <LinkButton
          onClick={() => {
            this.showFilters = !this.showFilters
          }}
          className="header-filter-link"
        ><FilterList/></LinkButton>
        Accounts
      </h2>
      {
        this.showFilters
          ? <div className="filters-list">
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>
                <MultipleSelect
                  fullWidth={true}
                  label="Account Status"
                  options={['Active', 'In-Active']}
                  values={this.props.filters.accountStatus}
                  onChange={values => {
                    this.props.onFiltersChanged({
                      ...this.props.filters,
                      accountStatus: values,
                    })
                  }}
                />
              </div>
              <div style={{ flex: 1 }}/>
            </div>
          </div>
          : null
      }
      <Paper style={{ padding: 20 }}>

        <AccountTotals
          accounts={this.visibleAccounts}
          selectedServiceIds={this.visibleSelectedServiceIds}
        />
        <br/><br/>
        <List className="account-list-expansion">
          {this.visibleAccounts.map(account => <AccountListItem
            key={account.id}
            account={account}
            selectedServiceIds={this.selectedServiceIds}
            onSelectedServicesChanged={selectedServiceIds => this.handleServiceSelection(account, selectedServiceIds)}
          />)}
        </List>
      </Paper>
    </div>
  }
}
