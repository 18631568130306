import BaseModel from './BaseModel'
import Link from './Link'
import TimelineItem from './TimelineItem'

export default class Timeline extends BaseModel {
  current: TimelineItem[] = []
  links: Link[] = []

  setCurrent (a?: []) {
    return a ? a.map(aa => new TimelineItem().init(aa)) : []
  }

  setLinks (a?: []) {
    return a ? a.map(aa => new Link().init(aa)) : []
  }
}