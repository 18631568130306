import BaseModel from "./BaseModel"
import Link from "./Link"
import { Moment } from 'moment'

export class Cases extends BaseModel {
  current: Case[] = []
  links: Link[] = []

  setCurrent (a?: []) {
    return a ? a.map(aa => new Case().init(aa)) : []
  }

  setLinks (a?: []) {
    return a ? a.map(aa => new Link().init(aa)) : []
  }
}

export class Case extends BaseModel {
  id: number
  date: Moment
  icon: string
  label: string
  active: boolean
  age: number

  details: CaseDetails
  points: CasePoint[]

  getCasts (): {} {
    return {
      id: 'number',
      date: 'datetime',
      active: 'boolean',
      age: 'number',
    }
  }

  setDetails (a?: any) {
    return a ? new CaseDetails().init(a) : null
  }

  setPoints (a?: []) {
    return a ? a.map(aa => new CasePoint().init(aa)) : []
  }
}

export class CaseDetails extends BaseModel {
  groupedValues: GroupedCaseValues[]
  comment: string
  status: CaseStatus
  availableStatuses: CaseStatus[]
  postbackUrl: string

  setGroupedValues (a?: []) {
    return a ? a.map(aa => new GroupedCaseValues().init(aa)) : []
  }

  setCaseStatus (a?: any) {
    return a ? new CaseStatus().init(a) : null
  }

  setAvailableStatuses (a?: []) {
    return a ? a.map(aa => new CaseStatus().init(aa)) : []
  }
}

export class GroupedCaseValues extends BaseModel {
  group: string
  values: CaseValue[]

  setValues (a?: []) {
    return a ? a.map(aa => new CaseValue().init(aa)) : []
  }
}

export class CaseValue extends BaseModel {
  label: string
  value: string
  editable: boolean
  type: string

  getCasts (): any {
    return {
      editable: 'boolean',
    }
  }
}

export class CaseStatus extends BaseModel {
  status: string
  description: string
}

export class CasePoint extends BaseModel {
  icon: string
  label: string
  subLabel: string
  date: Moment
  age: number
  groupedValues: GroupedCaseValues[]

  getCasts (): any {
    return {
      date: 'datetime',
      age: 'number',
    }
  }

  setGroupedValues (a?: []) {
    return a ? a.map(aa => new GroupedCaseValues().init(aa)) : []
  }
}
