import moment from "moment"
import React, { ReactNode } from "react";

export function textval (v: any) {
  switch (v) {
    case 'true':
      return true
    case 'false':
      return false
    case 'null':
      return null
    default:
      return v
  }
}

export function parseDate (date: string) {
  return date ? moment(date) : date
}

export function formatNumber (c: number, decPlaces: number = 0, thouSeparator: string | undefined = undefined, decSeparator: string | undefined = undefined, forceDecimal: boolean = false): string {
  decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces
  decSeparator = decSeparator === undefined ? '.' : decSeparator
  thouSeparator = thouSeparator === undefined ? ',' : thouSeparator
  let n: number = c
  let sign = n < 0 ? '-' : ''
  let s = Math.abs(+n || 0)
    .toFixed(decPlaces)
  n = Number(s)
  let i = parseInt(s) + ''
  let j = (i.length > 3) ? (i.length % 3) : 0

  let str = sign + (j ? i.substr(0, j) + thouSeparator : '') + i.substr(j)
    .replace(/(\d{3})(?=\d)/g, '$1' + thouSeparator)
  if (n % 1 !== 0 || forceDecimal) {
    return str + (decPlaces ? decSeparator + Math.abs(n - Number(i))
      .toFixed(decPlaces)
      .slice(2) : '')
  } else {
    return str
  }
}

type FormatCurrencyOptions = {
  forceDecimal?: boolean
  formatter?: (value: number, formatted: string) => string | ReactNode
}

export function formatCurrency (c: number, options?: FormatCurrencyOptions) {
  const opts: FormatCurrencyOptions = {
    forceDecimal: true,
    ...options,
  }
  const formatted = formatNumber(Math.abs(c), 2, undefined, undefined, opts.forceDecimal)

  return opts.formatter
    ? opts.formatter(c, `$${formatted}`)
    : c < 0 ? `($${formatted})` : `$${formatted}`
}

export function nl2br (str: string) {
  return str.trim().split('\n').map((item, key) => {
    return React.createElement('span', { key }, [React.createElement(React.Fragment, { key: 0 }, [item]), React.createElement('br', { key: 1 })])
  })
}
