import BaseModel from './BaseModel'
import TimelineItemService from './TimelineItemService'

export default class TimelineItemAccount extends BaseModel {
  id: number
  services: TimelineItemService[] = []

  setServices (a?: []) {
    return a ? a.map(aa => new TimelineItemService().init(aa)) : []
  }

  getCasts (): any {
    return {
      id: 'number',
    }
  }
}