import { observer } from "mobx-react"
import QuickActionsStore from "../stores/QuickActionsStore"
import React from "react"
import LazyStorePanel from "./LazyStorePanel"
import { Badge } from "@material-ui/core"
import QuickAction from "../models/QuickAction"
import Link from "../models/Link"
import _ from "lodash";
import LinkButton from "./LinkButton";
import DynamicIcon from './DynamicIcon'

type Props = {
  quickActionStore: QuickActionsStore
  onQuickActionClicked: (quickAction: QuickAction, url: string) => void
}

@observer
export default class QuickActionsComponent extends React.Component<Props> {
  private handleQuickActionClick = (quickAction: QuickAction, links: Link[]) => {
    this.props.onQuickActionClicked(quickAction, _.find(links, { rel: quickAction.rel })!.href)
  }

  render () {
    return <LazyStorePanel store={this.props.quickActionStore}>
      {
        quickActions => quickActions.current.map(quickAction => <LinkButton key={quickAction.id} className="left-nav-item" onClick={(ev) => {
            ev.preventDefault()
            this.handleQuickActionClick(quickAction, quickActions.links)
          }}>
            <Badge badgeContent={quickAction.badgeCount ? quickAction.badgeCount : null} color="primary">
              <DynamicIcon icon={quickAction.icon} className='left-nav-icon'/>
            </Badge>
            <div className="left-nav-label">
              {quickAction.label}
            </div>
          </LinkButton>
        )
      }
    </LazyStorePanel>
  }
}
