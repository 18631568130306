import React from "react";
import { formatCurrency } from "../common/Util";
import { observer } from "mobx-react";
import SimpleTransaction from '../models/SimpleTransaction'

type Props = {
  transactions: SimpleTransaction[]
  showPayor: boolean
}

@observer
export default class TransactionListComponent extends React.Component<Props> {
  static defaultProps = {
    showPayor: true,
  }

  render () {
    return <div className="item-list">
      {
        this.props.transactions.map(transaction => <div key={transaction.id} className="list-item">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ minWidth: 100 }}>{transaction.date.format('MM/DD/YYYY')}</div>
            <div style={{ flex: 1 }}>{transaction.type}</div>
            <div style={{ textAlign: 'right' }}>{formatCurrency(transaction.amount, {
              formatter: (value, formatted) => value < 0 ?
                <span className="negative-currency">-{formatted}</span> : formatted
            })}</div>
          </div>
          {
            this.props.showPayor
              ? <div style={{ color: '#999' }}>
                {transaction.payor}
              </div>
              : null
          }
        </div>)
      }
    </div>
  }
}
