import BaseModel from "./BaseModel"
import Link from "./Link"

export class Faqs extends BaseModel {
  current: Faq[] = []
  links: Link[] = []

  setCurrent (a?: []) {
    return a ? a.map(aa => new Faq().init(aa)) : []
  }

  setLinks (a?: []) {
    return a ? a.map(aa => new Link().init(aa)) : []
  }
}

export class Faq extends BaseModel {
  id: number
  icon: string
  label: string
  subLabel: string
  values: FaqValue[]

  setValues (a?: []) {
    return a ? a.map(aa => new FaqValue().init(aa)) : []
  }

  getCasts (): {} {
    return {
      id: 'number',
    }
  }
}

export class FaqValue extends BaseModel {
  label: string
  value: string
}