import React from 'react'
import { observer } from 'mobx-react'
import TimelineStore from '../stores/TimelineStore'
import LazyStorePanel from './LazyStorePanel'
import { Card, CardContent, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core'
import _ from 'lodash';
import TimelineItem from '../models/TimelineItem'
import { Moment } from 'moment'
import classNames from 'classnames'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { computed } from 'mobx'
import Service from '../models/Service'

type Props = {
  timelineStore: TimelineStore
  visibleServices: Service[]
}

type GroupedItems = {
  key: string
  title: string
  date: Moment
  items: TimelineItem[]
}

@observer
export default class TimelineComponent extends React.Component<Props> {
  private groupItems = (items: TimelineItem[]) => {
    const groups: GroupedItems[] = []

    items.forEach(item => {
      const key = item.date.format('YYYY-MM')
      let group = _.find(groups, g => g.key === key)

      if (!group) {
        group = {
          key: key,
          title: item.date.format('MMM Y'),
          date: item.date,
          items: [],
        }

        groups.push(group)
      }

      group.items.push(item)
    })

    return _.orderBy(groups, g => g.date.format('YYYY-MM-DD'), ['desc'])
  }

  @computed
  private get timelineItems () {
    return this.props.timelineStore.data ? this.props.timelineStore.data.current : []
  }

  @computed get visibleTimelineItems () {
    return this.timelineItems.filter(timelineItem => {
      return _.intersection(_.flatMap(_.flatMap(timelineItem.accounts, a => a.services), s => s.id), _.flatMap(this.props.visibleServices, s => s.id)).length > 0
    })
  }

  @computed
  private get groupedItems () {
    return this.groupItems(this.visibleTimelineItems)
  }

  render () {
    return <Card>
      <CardContent>
        <LazyStorePanel store={this.props.timelineStore}>
          {timeline => <div className="timeline">
            {
              this.groupedItems.map((group, groupIdx) => group.items.map((item, idx) => <div key={item.id} className={classNames('timeline-item', { 'first-item': groupIdx === 0 && idx === 0 }, { 'last-item': groupIdx === this.groupedItems.length - 1 && idx === group.items.length - 1 })}>
                <div className="timeline-month">
                  {idx === 0 ? group.title : null}
                </div>
                <div className="timeline-dot">
                  <div className="line"/>
                  <div className="dot"/>
                </div>
                <div className="timeline-content">
                  <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                      {item.header}&nbsp;<span className="more-text">• {item.date.format('MM/DD/YY')}</span>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {
                          item.groupedValues.map((groupedValues, idx) => <div key={idx}>
                            <div><b>{groupedValues.group}</b></div>
                            <div style={{ marginLeft: 30 }}>
                              <table className="timeline-values-table">
                                <tbody>
                                {groupedValues.values.map((v, idx) => <tr key={idx}>
                                  <td style={{ width: 150 }}><b>{v.label}</b></td>
                                  <td>
                                    <div dangerouslySetInnerHTML={{ __html: v.value }}/>
                                  </td>
                                </tr>)}
                                </tbody>
                              </table>
                            </div>
                          </div>)
                        }
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              </div>))
            }
          </div>}
        </LazyStorePanel>
      </CardContent>
    </Card>
  }
}
