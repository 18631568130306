import axios, { CancelTokenSource } from 'axios'
import Config from "../common/Config"
import StoreDirectory from '../stores/StoreDirectory'
import Link from '../models/Link'
import { ApiClientInterface } from './ApiClientFactory'

axios.defaults.headers.post['Content-Type'] = 'application/json'

//axios.defaults.headers.post['Accept'] = 'application/json'

export interface RequestOptions {
  cancelTokenSource?: CancelTokenSource
}

class ApiClient implements ApiClientInterface {
  getInstance = () => {
    let params = {
      maxContentLength: 10000,
      timeout: 30000,
    }

    const headers = {}

    const instance = axios.create({
      ...params,
      baseURL: Config.API_URL,
      headers: headers,
    })

    instance.interceptors.response.use(response => {
      if (response.data.links) {
        try {
          StoreDirectory.populateStores(response.data.links.map((l: any) => new Link().init(l)))
        } catch (e) {
          console.log(e)
        }
      }
      return response
    })


    return instance
  }
}

export default new ApiClient()

export function transformApiUrl (url: string): string {
  return url
  //return url.replace(/https:\/\/stepper.azurewebsites.net\//, "http://localhost:8010/proxy/")
}