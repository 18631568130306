import { observer } from "mobx-react";
import React from "react";
import { formatCurrency } from "../common/Util";
import { AmountAggregate } from "../models/AmountAggregate";

export type Props = {
  amounts: AmountAggregate[]
  footerLabel: string
  footerAmount: number
}

@observer
export default class AmountsListComponent extends React.Component<Props> {
  render () {
    return <>
      {
        this.props.amounts.map(b => <div key={b.label} style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flex: 1 }}>
            {b.label}
          </div>
          <div style={{ textAlign: 'right' }}>
            {formatCurrency(b.total)}
          </div>
        </div>)
      }

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ flex: 1, fontWeight: 'bold' }}>
          {this.props.footerLabel}
        </div>
        <div style={{ textAlign: 'right' }}>
          {formatCurrency(this.props.footerAmount)}
        </div>
      </div>
    </>
  }
}