import BaseModel from "./BaseModel"
import Link from "./Link"
import { Moment } from 'moment'

export class Alerts extends BaseModel {
  current: Alert[] = []
  links: Link[] = []

  setCurrent (a?: []) {
    return a ? a.map(aa => new Alert().init(aa)) : []
  }

  setLinks (a?: []) {
    return a ? a.map(aa => new Link().init(aa)) : []
  }
}

export class Alert extends BaseModel {
  id: number
  label: string
  body: string
  active: boolean
  history: AlertHistory[]
  postbackUrl: string

  setHistory (a?: []) {
    return a ? a.map(aa => new AlertHistory().init(aa)) : []
  }

  getCasts (): {} {
    return {
      id: 'number',
      active: 'boolean',
    }
  }
}

export class AlertHistory extends BaseModel {
  user: string
  date: Moment

  getCasts (): any {
    return {
      date: 'datetime',
    }
  }
}