import { AxiosInstance } from 'axios'

class ApiClientFactory {
  private apiClient: ApiClientInterface

  setInstance = (apiClient: ApiClientInterface) => {
    this.apiClient = apiClient
  }

  getInstance = () => {
    return this.apiClient.getInstance()
  }
}

export interface ApiClientInterface {
  getInstance (): AxiosInstance
}

export default new ApiClientFactory()