import { observer } from "mobx-react";
import React from "react";
import { GroupedServices } from "./ServicesList";
import { formatCurrency } from "../common/Util";
import _ from "lodash";
import ServiceListItem from "./ServiceListItem";
import { Paper } from "@material-ui/core";
import { observable } from "mobx";
import AccountBalancePopover from "./popovers/AccountBalancePopover";
import LinkButton from "./LinkButton";

type Props = {
  groupedServices: GroupedServices
}

@observer
export default class GroupedServicesComponent extends React.Component<Props> {
  @observable private accountBalanceEl?: Element

  render () {
    const { groupedServices } = this.props

    return <>
      <Paper style={{ padding: 20 }}>
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20 }}>
          <h3 style={{ margin: 0 }}>{groupedServices.clientName}</h3>
          <div style={{ flex: 1, textAlign: 'center' }}><b>{groupedServices.responsibleParty}</b> - Responsible
            Party
          </div>
          <div>
            <LinkButton onClick={ev => {
              this.accountBalanceEl = ev.currentTarget
            }}>{formatCurrency(_.sumBy(groupedServices.services, s => s.balance))}</LinkButton>
          </div>
        </div>
        {
          groupedServices.services.map(service => <ServiceListItem
            key={service.id}
            service={service}/>)
        }
      </Paper>
      {
        this.accountBalanceEl
          ? <AccountBalancePopover
            services={groupedServices.services}
            anchorEl={this.accountBalanceEl}
            onClosed={() => this.accountBalanceEl = undefined}
          />
          : null
      }
    </>
  }
}