import React from "react"
import DynamicStore from "../stores/DynamicStore"
import { observer } from "mobx-react"
import BaseModel from "../models/BaseModel"
import { autorun } from "mobx"
import { CircularProgress } from "@material-ui/core";

type Props<T extends BaseModel> = {
  store: DynamicStore<T>
  children: (resource: T) => React.ReactNode
}

@observer
export default class LazyStorePanel<T extends BaseModel> extends React.Component<Props<T>> {
  componentDidMount (): void {
    // make sure we have fetched data
    this.props.store.fetch().then()

    autorun(() => {
      if (this.props.store.invalidated) {
        this.props.store.fetch().then()
      }
    })
  }

  private renderContent = (store: DynamicStore<any>) => {
    if (store.error) {
      return `ERROR: ${store.error}`
    } else if (store.fetched && store.data) {
      return this.props.children(store.data)
    } else {
      return null
    }
  }

  private renderLoader = () => {
    return <div className="lazy-store-panel-loader">
      <CircularProgress size={24}/>
    </div>
  }

  render () {
    const store = this.props.store

    return <div className="lazy-store-panel">
      {this.renderContent(store)}
      {
        store.fetching
          ? this.renderLoader()
          : null
      }
    </div>
  }
}
