import React from 'react'
import { Paper } from '@material-ui/core'
import TransactionsStore from '../stores/TransactionsStore'
import MaterialTable from 'material-table'
import { computed } from 'mobx'
import { observer } from 'mobx-react'
import { GroupedTransactionValues, Transaction } from '../models/Transaction'
import { formatCurrency } from '../common/Util'
import Service from '../models/Service'
import _ from 'lodash'

type Props = {
  transactionsStore: TransactionsStore
  visibleServices: Service[]
}

@observer
export default class TransactionsComponent extends React.Component<Props> {
  private tableColumns = [
    {
      title: 'Date',
      field: 'date',
      render: (data: Transaction) => data.date.format('MM/DD/YYYY'),
      customFilterAndSearch: (term: string, data: Transaction) =>
        (
          data.date.format('MM/DD/YYYY').indexOf(term) > -1
          ||
          _.flatMap(data.groupedValues.map((groupedValue: GroupedTransactionValues) => groupedValue.values.map(v => v.value))).join(', ').toLowerCase().indexOf(term.toLowerCase()) > -1
        ),
    },
    {
      title: 'Ref #',
      field: 'reference',
    },
    {
      title: 'Type',
      field: 'type',
    },
    {
      title: 'Description',
      field: 'description',
    },
    {
      title: 'Status',
      field: 'status',
      render: (data: Transaction) => data.status.status,
      customFilterAndSearch: (term: string, data: Transaction) => String(data.status.status).toLowerCase().indexOf(term.toLowerCase()) > -1,
    },
    {
      title: 'Amount',
      field: 'amount',
      headerStyle: { textAlign: 'right' as const },
      cellStyle: { textAlign: 'right' as const },
      render: (data: Transaction) => formatCurrency(data.amount),
      customFilterAndSearch: (term: string, data: Transaction) => String(formatCurrency(data.amount)).indexOf(term) > -1,
    },
  ]

  @computed get visibleTransactions () {
    return this.transactions.filter(transaction => {
      return _.intersection(_.flatMap(_.flatMap(transaction.accounts, a => a.services), s => s.id), _.flatMap(this.props.visibleServices, s => s.id)).length > 0
    })
  }

  @computed get transactions () {
    return this.props.transactionsStore.data ? this.props.transactionsStore.data.current : []
  }

  @computed get tableData () {
    return this.visibleTransactions
  }

  private renderDetailPanel = (rowData: Transaction) => {
    return <div style={{ marginLeft: 60 }}>
      {
        rowData.groupedValues.map((groupedValue: GroupedTransactionValues, idx: number) => <div key={idx}>
          <div><b>{groupedValue.group}</b></div>
          <div style={{ marginLeft: 30 }}>
            <table className="timeline-values-table">
              <tbody>
              {groupedValue.values.map((v, idx) => <tr key={idx}>
                <td style={{ width: 150 }}><b>{v.label}</b></td>
                <td>
                  <div dangerouslySetInnerHTML={{ __html: v.value }}/>
                </td>
              </tr>)}
              </tbody>
            </table>
          </div>
        </div>)
      }
    </div>
  }

  componentDidMount (): void {
    this.props.transactionsStore.fetch().then()
  }

  render () {
    return <Paper>
      <MaterialTable
        columns={this.tableColumns}
        data={this.tableData}
        detailPanel={this.renderDetailPanel}
        title=""
      />
    </Paper>
  }
}
