import BaseModel from "./BaseModel"
import Link from "./Link"
import { Moment } from 'moment'

export class Notes extends BaseModel {
  current: Note[] = []
  links: Link[] = []

  setCurrent (a?: []) {
    return a ? a.map(aa => new Note().init(aa)) : []
  }

  setLinks (a?: []) {
    return a ? a.map(aa => new Link().init(aa)) : []
  }
}

export class Note extends BaseModel {
  icon: string
  label: string
  date: Moment
  user: string
  text: string
  id: number

  getCasts (): {} {
    return {
      id: 'number',
      date: 'datetime',
    }
  }
}
