import { observer } from "mobx-react"
import React from "react"
import LazyStorePanel from "./LazyStorePanel"
import { Chip } from "@material-ui/core"
import AgentStore from "../stores/AgentStore"
import { computed, observable } from "mobx"
import AgentForPopover from './popovers/AgentForPopover'

type Props = {
  agentStore: AgentStore
}

@observer
export default class AgentComponent extends React.Component<Props> {
  @observable private agentEl?: Element

  @computed
  private get agent () {
    return this.props.agentStore.data ? this.props.agentStore.data : undefined
  }

  render () {
    return <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ marginRight: 10 }}>Agent For:</div>
        <LazyStorePanel store={this.props.agentStore}>
          {
            agent => <Chip onClick={ev => this.agentEl = ev.currentTarget} label={agent.name}/>
          }
        </LazyStorePanel>
      </div>
      {
        this.agentEl && this.agent
          ? <AgentForPopover
            anchorEl={this.agentEl}
            agent={this.agent}
            onClosed={() => this.agentEl = undefined}
          />
          : null
      }
    </>
  }
}
