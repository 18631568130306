import BaseModel from './BaseModel'
import TimelineValue from './TimelineValue'

export default class GroupedTimelineValues extends BaseModel {
  group: string
  values: TimelineValue[] = []

  setTimelineValue (a?: []) {
    return a ? a.map(aa => new TimelineValue().init(aa)) : []
  }
}