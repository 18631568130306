import { StepperStepDescriptor } from "../models/StepperStepDescriptor"
import React from "react"
import { observer } from "mobx-react"
import { Button, Grid } from "@material-ui/core"

type DStepButtonsProps = {
  stepIndex: number
  step: StepperStepDescriptor
  onNavigationButtonClicked: (action: string, postData: any) => void
  buttonVariant?: 'text' | 'outlined' | 'contained'
}

@observer
export default class DStepButtons extends React.Component<DStepButtonsProps> {
  private handleNavigationButton = (action: string) => {
    this.props.onNavigationButtonClicked(action, {})
  }

  render () {
    const { step } = this.props

    return <Grid container justify="flex-end">

      {
        (step.optional && !step.nextLabel)
          ? <Button
            disabled={!step.postbackURL}
            onClick={() => {
              this.handleNavigationButton('Skip')
            }}
            variant={this.props.buttonVariant || 'text'}
          >{step.skipLabel || 'Skip'}</Button>
          : null
      }
      {
        this.props.stepIndex > 0
          ? <Button
            style={{ marginLeft: 8 }}
            disabled={!step.postbackURL}
            onClick={() => {
              this.handleNavigationButton('Back')
            }}
            variant={this.props.buttonVariant || 'text'}
          >{step.backLabel || 'Back'}</Button>
          : null
      }
      <Button
        style={{ marginLeft: 8 }}
        disabled={!step.postbackURL}
        variant={this.props.buttonVariant || 'text'}
        color="primary"
        onClick={() => {
          this.handleNavigationButton('Next')
        }}
      >{step.nextLabel || 'Next'}</Button>
    </Grid>
  }
}