import BaseModel from "./BaseModel"

export default class Amount extends BaseModel {
  index: number
  label: string
  total: number

  getCasts (): {} {
    return {
      index: 'number',
      total: 'number',
    }
  }
}
