import { observer } from "mobx-react"
import * as React from "react"
import { TextField } from "@material-ui/core"

type Props = {
  id: string
  title: string
  value: string
  onChange: (value: string) => void
}

@observer
export default class DFormTextField extends React.Component<Props> {
  onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(ev.target.value)
  }

  render () {
    return <TextField
      id={this.props.id}
      label={this.props.title}
      value={this.props.value}
      fullWidth
    />
  }
}