import * as React from "react"
import { observer } from "mobx-react"
import { Card, CardContent, Checkbox, Grid } from "@material-ui/core"

type Props = {
  client: string
  reference: string
  name: string
  address: string
  phone: string
  amount: string
  selected: boolean

  onChange: (selected: boolean) => void
}

@observer
export default class DSelectAccountCard extends React.Component<Props> {
  render () {
    return <Card>
      <CardContent>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <div>{this.props.client}</div>
            <Grid container>
              <Grid item xs={4}>
                {this.props.reference}<br/>
                {this.props.name}
              </Grid>
              <Grid item xs={4}>
                <div dangerouslySetInnerHTML={{ __html: this.props.address }}/>
              </Grid>
              <Grid item xs={4}>
                {this.props.phone}
              </Grid>
            </Grid>
          </div>
          <div style={{ textAlign: 'right' }}>
            {this.props.amount}<br/>
            <Checkbox checked={this.props.selected} onChange={ev => this.props.onChange(ev.target.checked)}/>
          </div>
        </div>
      </CardContent>
    </Card>
  }
}