import Amount from "./Amount";
import _ from "lodash";

export type AmountAggregate = {
  total: number
  index: number
  label: string
}

export function computeAmountAggregates (amounts: Amount[]) {
  return _.sortBy(amounts.reduce((prev: AmountAggregate[], amount) => {
    let agg = _.find(prev, a => a.label === amount.label)

    if (!agg) {
      agg = {
        total: 0,
        index: amount.index,
        label: amount.label,
      }
      prev.push(agg)
    }

    agg.total += amount.total

    return prev
  }, []), a => a.index)
}