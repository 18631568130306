import BaseModel from "./BaseModel"
import { Moment } from "moment"

export default class Claim extends BaseModel {
  date: Moment
  description: string

  getCasts (): {} {
    return {
      date: 'datetime',
    }
  }
}
