import * as React from "react"
import { observer } from "mobx-react"
import CloseIcon from '@material-ui/icons/Close'
import { Card, CardContent } from "@material-ui/core"
import LazyStorePanel from "../LazyStorePanel"
import { nl2br } from '../../common/Util'
import LinkButton from '../LinkButton'
import ApiClientFactory from '../../api/ApiClientFactory'
import DynamicIcon from '../DynamicIcon'
import { observable } from 'mobx'
import AlertsStore from '../../stores/AlertsStore'

type Props = {
  href: string
  onCloseClicked: () => {}
}

@observer
export default class AlertsDrawer extends React.Component<Props> {
  @observable private alertsStore?: AlertsStore

  componentDidMount (): void {
    const alertsStore = new AlertsStore()
    alertsStore.href = this.props.href

    this.alertsStore = alertsStore
  }

  private markRead = (url: string) => {
    ApiClientFactory.getInstance()
      .post(url)
      .then(() => this.alertsStore!.invalidate())
  }

  render () {
    return <div className="app-drawer">
      <div className="app-drawer-header">
        <h2>Alerts</h2>
        <div
          className="close-button"
          onClick={(ev) => {
            ev.preventDefault()
            this.props.onCloseClicked()
          }}
        ><CloseIcon/></div>
      </div>
      <div className="app-drawer-content">
        {
          this.alertsStore
            ? <LazyStorePanel
              store={this.alertsStore}>
              {alerts => alerts.current.map(alert => <Card key={alert.id} elevation={1} style={{ marginBottom: 20 }}>
                <CardContent>
                  <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                      <DynamicIcon icon={alert.icon}/> {alert.label}
                    </div>
                    {
                      alert.active
                        ? <div>
                          <LinkButton onClick={() => this.markRead(alert.postbackUrl)}>Mark Read</LinkButton>
                        </div>
                        : null
                    }
                  </div>
                  <div style={{ marginTop: 10 }}>
                    {nl2br(alert.body)}
                  </div>
                </CardContent>
              </Card>)
              }
            </LazyStorePanel>
            : null
        }
      </div>
    </div>
  }
}
