import * as React from "react"
import { observer } from "mobx-react"
import CloseIcon from '@material-ui/icons/Close'
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from "@material-ui/core"
import { observable } from "mobx"
import LazyStorePanel from "../LazyStorePanel"
import FaqsStore from '../../stores/FaqsStore'
import DynamicIcon from '../DynamicIcon'

type Props = {
  href: string
  onCloseClicked: () => {}
}

@observer
export default class FaqsDrawer extends React.Component<Props> {
  @observable private faqsStore?: FaqsStore

  componentDidMount (): void {
    const faqsStore = new FaqsStore()
    faqsStore.href = this.props.href

    this.faqsStore = faqsStore
  }

  render () {
    return <div className="app-drawer">
      <div className="app-drawer-header">
        <h2>FAQs</h2>
        <div
          className="close-button"
          onClick={(ev) => {
            ev.preventDefault()
            this.props.onCloseClicked()
          }}
        ><CloseIcon/></div>
      </div>
      <div className="app-drawer-content">
        {
          this.faqsStore
            ? <LazyStorePanel
              store={this.faqsStore}>
              {faqs => faqs.current.map(faq =>
                <ExpansionPanel key={faq.id} style={{ marginBottom: 20 }}>
                  <ExpansionPanelSummary>
                    <DynamicIcon icon={faq.icon}/> {faq.label} {faq.label} • {faq.subLabel}
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    {
                      faq.values.map((value, idx) =>
                        <div key={idx} style={{ display: 'flex' }}>
                          <div style={{ width: 200 }}>
                            <b>{value.label}</b>
                          </div>
                          <div style={{ flex: 1 }}>
                            <div dangerouslySetInnerHTML={{ __html: value.value }}/>
                          </div>
                        </div>
                      )
                    }
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )
              }
            </LazyStorePanel>
            : null
        }
      </div>
    </div>
  }
}
