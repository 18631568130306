import React from "react"
import { observer } from "mobx-react"
import Account from '../models/Account'
import { Paper } from "@material-ui/core"
import { computed } from "mobx"
import _ from "lodash";
import Service from "../models/Service"
import GroupedServicesComponent from "./GroupedServicesComponent";

type Props = {
  accounts: Account[]
  selectedServiceIds: number[]
}

export type GroupedService = {
  service: Service
  account: Account
}

export type GroupedServices = {
  id: string
  clientName: string
  responsibleParty: string
  services: Service[]
}

@observer
export default class ServicesList extends React.Component<Props> {
  @computed get selectedServices (): GroupedService[] {
    return _.flatMap(this.props.accounts, a => a.services.map(s => ({
      account: a,
      service: s
    }))).filter(service => this.props.selectedServiceIds.indexOf(service.service.id) > -1)
  }

  @computed get groupedServices (): GroupedServices[] {
    const flatServices = this.selectedServices

    const groupedServices = _.groupBy(flatServices, fs => `${fs.account.responsibleParty}-${fs.account.clientName}`)

    return _.map(groupedServices, gs => ({
      id: `${gs[0].account.clientName}-${gs[0].account.responsibleParty}`,
      clientName: gs[0].account.clientName,
      responsibleParty: gs[0].account.responsibleParty,
      services: gs.map(s => s.service),
    }))
  }

  render () {
    return <div>
      <Paper style={{ padding: 20 }}>
        {
          this.groupedServices.map(groupedServices => <div className="service-list-grouped" key={groupedServices.id}>
            <GroupedServicesComponent groupedServices={groupedServices}/>
          </div>)
        }
      </Paper>
    </div>
  }
}