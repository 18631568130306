export enum RowType {
  KeyValue = 'keyvalue',
  AccountCard = 'accountcard',
}

export enum RowAlertType {
  Warning = 'warning',
  Danger = 'danger',
}

export type SummaryRowKeyValueData = {
  key: string
  value: string
}

export type StepperSummarySectionRowDescriptor = {
  type: RowType
  alert: RowAlertType
  value: SummaryRowKeyValueData | any
}