import { StepperFormDescriptor } from "../models/StepperFormDescriptor"
import { observer } from "mobx-react"
import * as React from "react"
import { FormFieldType } from "./DFormField"
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, RadioGroup, TextField } from "@material-ui/core"
import DSelectAccountCard from "./DSelectAccountCard"
import { observable, toJS } from "mobx"
import _ from "lodash";
import Radio from "@material-ui/core/Radio"

type Props = {
  form: StepperFormDescriptor
  onFormDataChanged: (formData: any) => void
}

@observer
export default class DForm extends React.Component<Props> {
  @observable private formData: { [id: string]: any } = {}

  componentDidMount (): void {
    this.formData = this.props.form.data
  }

  private onFieldValueChanged = (id: string, value: any) => {
    this.formData[id] = value

    this.props.onFormDataChanged(toJS(this.formData))
  }

  private onArrayDataChanged = (fieldId: string, optionId: string, selected: boolean) => {
    const data = _.find(this.formData[fieldId], d => d.id === optionId)

    if (data) {
      data.selected = selected
    }

    this.props.onFormDataChanged(toJS(this.formData));
  }

  render () {
    const form = this.props.form

    return <form>
      {
        form.description
          ? <p dangerouslySetInnerHTML={{ __html: form.description }}/>
          : null
      }
      {
        form.fields.map((field, idx) => {
          if (field.type === FormFieldType.TextField) {
            return <FormGroup row key={idx}>
              <TextField
                id={field.id}
                label={field.title}
                value={form.data[field.id]}
                onChange={ev => this.onFieldValueChanged(field.id, ev.target.value)}
                fullWidth
              />
            </FormGroup>
          } else if (field.type === FormFieldType.Checkbox) {
            return <FormGroup row key={idx}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={form.data[field.id]}
                    onChange={ev => this.onFieldValueChanged(field.id, ev.target.checked)}
                  />
                }
                label={field.title}
              />
            </FormGroup>
          } else if (field.type === FormFieldType.Array) {
            if (field.items.type === 'accountcard') {
              return form.data.accounts.map((data: any) => <div key={data.id} style={{ marginBottom: 10 }}>
                <DSelectAccountCard
                  {...data}
                  onChange={selected => this.onArrayDataChanged(field.id, data.id, selected)}
                />
              </div>)
            }
          } else if (field.type === FormFieldType.Date) {
            return <FormGroup row key={idx}>
              <TextField
                type="date"
                id={field.id}
                label={field.title}
                value={form.data[field.id]}
                onChange={ev => this.onFieldValueChanged(field.id, ev.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormGroup>
          } else if (field.type === FormFieldType.PhoneNumber) {
            return <FormGroup row key={idx}>
              <TextField
                type="tel"
                id={field.id}
                label={field.title}
                value={form.data[field.id]}
                onChange={ev => this.onFieldValueChanged(field.id, ev.target.value)}
              />
            </FormGroup>
          } else if (field.type === FormFieldType.Radio) {
            return <FormControl component="fieldset">
              <FormLabel component="legend">{field.title}</FormLabel>
              <RadioGroup
                value={form.data[field.id]}
                onChange={ev => this.onFieldValueChanged(field.id, (ev.target as HTMLInputElement).value)}
              >
                {
                  field.enum.map(value => <FormControlLabel value={value} control={<Radio />} label={value} />)
                }
              </RadioGroup>
            </FormControl>
          }
          return null
        })
      }
    </form>
  }
}