import BaseModel from "./BaseModel"
import Amount from "./Amount"
import Claim from "./Claim"
import SimpleTransaction from './SimpleTransaction'

export default class AgentExtendedInformation extends BaseModel {
  longLabel: string
  name: string
  subscriberName: string
  memberID: string
  group: string
  amounts: Amount[]
  transactions: SimpleTransaction[]
  claims: Claim[]

  setAmounts (a?: []) {
    return a ? a.map(aa => new Amount().init(aa)) : []
  }

  setTransactions (a?: []) {
    return a ? a.map(aa => new SimpleTransaction().init(aa)) : []
  }

  setClaims (a?: []) {
    return a ? a.map(aa => new Claim().init(aa)) : []
  }
}
