import { StepperSummaryDescriptor } from "./StepperSummaryDescriptor"
import { StepperStepDescriptor } from "./StepperStepDescriptor"

export enum StepperOrientation {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export type StepperDescriptor = {
  id: number
  name: string
  orientation: StepperOrientation
  complete: boolean
  summary: StepperSummaryDescriptor | null
  steps: StepperStepDescriptor[]
}