import React from "react";
import Account from '../models/Account'
import { observer } from "mobx-react";
import { computed, observable } from "mobx";
import _ from "lodash";
import { formatCurrency } from "../common/Util";
import AccountBalancePopover from "./popovers/AccountBalancePopover";
import LinkButton from "./LinkButton";
import { AmountAggregate, computeAmountAggregates } from "../models/AmountAggregate";

type Props = {
  accounts: Account[]
  selectedServiceIds: number[]
}

@observer
export default class AccountTotals extends React.Component<Props> {
  @observable private accountBalanceEl?: Element

  @computed get selectedServices () {
    return _.flatMap(this.props.accounts, a => a.services.map(s => ({
      account: a,
      service: s
    }))).filter(service => this.props.selectedServiceIds.indexOf(service.service.id) > -1)
  }

  @computed get balance (): number {
    return _.sumBy(this.selectedServices, s => s.service.balance)
  }

  @computed get fees (): AmountAggregate[] {
    return computeAmountAggregates(_.flatMap(this.selectedServices, s => s.service.fees))
  }

  @computed get total () {
    return _.sumBy(this.fees, f => f.total) + this.balance
  }

  render () {
    return <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexDirection: 'row', fontSize: 14, marginBottom: 10 }}>
        <div style={{ flex: 1 }}>
          TOTAL
        </div>
        <div style={{ textAlign: 'right', fontWeight: 'bold' }}>
          {formatCurrency(this.total)}
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', fontSize: 14 }}>
        <div style={{ flex: 1, color: '#666' }}>
          ACCOUNT BALANCE
        </div>
        <div style={{ textAlign: 'right' }}>
          <LinkButton onClick={ev => {
            this.accountBalanceEl = ev.currentTarget
          }}>{formatCurrency(this.balance)}</LinkButton>
        </div>
      </div>

      {
        this.accountBalanceEl
          ? <AccountBalancePopover
            anchorEl={this.accountBalanceEl}
            services={_.flatMap(this.selectedServices, s => s.service)}
            onClosed={() => this.accountBalanceEl = undefined}
            showDueByPatientBreakDown={false}
          />
          : null
      }

      {
        this.fees.map(b => <div key={b.label} style={{ display: 'flex', flexDirection: 'row', fontSize: 14 }}>
          <div style={{ flex: 1, color: '#666' }}>
            {b.label}
          </div>
          <div style={{ textAlign: 'right' }}>
            {formatCurrency(b.total)}
          </div>
        </div>)
      }
    </div>
  }
}