import * as React from "react"
import { observer } from "mobx-react"
import CloseIcon from '@material-ui/icons/Close'
import { computed, observable } from 'mobx'
import ContactsStore from '../../stores/ContactsStore'
import LazyStorePanel from '../LazyStorePanel'
import { Contact } from '../../models/Contact'
import { Tab, Tabs } from '@material-ui/core'

type Props = {
  onCloseClicked: () => {}
  href: string
}

type GroupedContactsType = {
  'Responsible Party': Contact[],
  'Authorized Contact': Contact[],
  'Patient': Contact[],
}

@observer
export default class ContactsDrawer extends React.Component<Props> {
  @observable private contactStore?: ContactsStore
  @observable private activeTab: 'Responsible Party' | 'Authorized Contact' | 'Patient' = 'Responsible Party'

  @computed get contacts () {
    return (this.contactStore && this.contactStore.data)
      ? this.contactStore.data.current
      : []
  }

  @computed get groupedContacts (): GroupedContactsType {
    const grouped: GroupedContactsType = {
      'Responsible Party': [],
      'Authorized Contact': [],
      'Patient': [],
    }

    this.contacts.forEach(contact => {
      if (grouped.hasOwnProperty(contact.type)) {
        grouped[contact.type].push(contact)
      }
    })

    return grouped
  }

  componentDidMount (): void {
    const contactsStore = new ContactsStore()
    contactsStore.href = this.props.href

    this.contactStore = contactsStore
  }

  private handleTabChange = (ev: React.ChangeEvent<{}>, newValue: string) => {
    (this.activeTab as any) = newValue
  }

  render () {
    return <div className="app-drawer">
      <div className="app-drawer-header">
        <h2>Contacts</h2>
        <div
          className="close-button"
          onClick={(ev) => {
            ev.preventDefault()
            this.props.onCloseClicked()
          }}
        ><CloseIcon/></div>
      </div>
      <div className="app-drawer-content">
        {
          this.contactStore
            ? <LazyStorePanel
              store={this.contactStore}>
              {
                () => <>
                  <Tabs value={this.activeTab} onChange={this.handleTabChange}>
                    {
                      this.groupedContacts['Responsible Party'].length
                        ? <Tab value="Responsible Party" label="Resp. Party"/>
                        : null
                    }
                    {
                      this.groupedContacts['Patient'].length
                        ? <Tab value="Patient" label="Patient"/>
                        : null
                    }

                    {
                      this.groupedContacts['Authorized Contact'].length
                        ? <Tab value="Authorized Contact" label="Auth. Contact"/>
                        : null
                    }
                  </Tabs>
                  <div>
                    {
                      this.groupedContacts.hasOwnProperty(this.activeTab)
                        ? <>
                          {
                            this.groupedContacts[this.activeTab].map(contact => <div key={contact.id}>
                              {contact.name}
                            </div>)
                          }
                        </>
                        : null
                    }
                  </div>
                </>
              }
            </LazyStorePanel>
            : null
        }
      </div>
    </div>
  }
}
