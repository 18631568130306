import BaseModel from "./BaseModel"
import InsuranceExtendedInformation from "./InsuranceExtendedInformation"

export default class Insurance extends BaseModel {
  id: string
  alert: number
  shortLabel: string
  description: string
  extendedInformation: InsuranceExtendedInformation

  getCasts (): any {
    return {
      alert: 'number',
    }
  }

  setExtendedInformation (a?: {}) {
    return a ? new InsuranceExtendedInformation().init(a) : undefined
  }
}
