import BaseModel from "./BaseModel"
import { Moment } from "moment"
import Amount from "./Amount"
import QuickActions from "./QuickActions"

import Insurance from "./Insurance"
import SimpleTransaction from './SimpleTransaction'

export default class Service extends BaseModel {
  id: number
  reference: string
  patientName: string
  facility: string
  dateOfService: Moment
  active: boolean
  quickActions: QuickActions
  totalCharges: number
  balance: number
  fees: Amount[] = []
  amounts: Amount[] = []
  balances: Amount[] = []
  balancesHeader: string
  transactions: SimpleTransaction[]
  insurances: Insurance[]
  physicians: string[]
  services: string[]
  exclusions: string[]

  getCasts (): {} {
    return {
      id: 'number',
      dateOfService: 'datetime',
      totalCharges: 'number',
      balance: 'number',
    }
  }

  setQuickActions (a?: {}) {
    return a ? new QuickActions().init(a) : undefined
  }

  setFees (a?: []) {
    return a ? a.map(aa => new Amount().init(aa)) : []
  }

  setAmounts (a?: []) {
    return a ? a.map(aa => new Amount().init(aa)) : []
  }

  setBalances (a?: []) {
    return a ? a.map(aa => new Amount().init(aa)) : []
  }

  setTransactions (a?: []) {
    return a ? a.map(aa => new SimpleTransaction().init(aa)) : []
  }

  setInsurances (a?: []) {
    return a ? a.map(aa => new Insurance().init(aa)) : []
  }
}
